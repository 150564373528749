import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  Spinner,
} from 'components';
import AtomSelect from 'components/atoms/Select';
import { AlertWarning } from 'components/molecules/Alert';
import QuestionBadge from 'components/molecules/Badge/QuestionBadge';
import CardForm from 'components/molecules/CardForm';
import React, { useEffect } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  getDetailSafeguards,
  postSafeguardsStep5,
} from 'services/danaProgram/callForProposalService';
import { getScreeningList } from 'services/masterData/screening';
import * as yup from 'yup';

const schema = yup.object({
  id: yup.string().required(),
  screening: yup.array().of(
    yup.object().shape({
      answer: yup.string().required('Wajib diisi'),
      description: yup.string(),
      name: yup.string().required(),
      selectedPrinciple: yup.array().of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.object().shape({
            deskripsi: yup.string().required(),
            id: yup.string().required(),
            idIsp: yup.string().required(),
            nama: yup.string().required(),
          }),
        })
      ),
    })
  ),
});

const Screening = ({ onNext, onBack, type }) => {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: '',
      screening: [],
    },
  });

  const { control } = methods;

  const { id, editable } = useSelector(
    (state) => state?.callForProposalData?.data
  );

  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    queryKey: ['get-detail-safeguard-5'],
    queryFn: async () => {
      const res = await getDetailSafeguards({ proposal_id: id });
      return res.data.data;
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ['get-screening-list'],
    queryFn: async () => {
      const res = await getScreeningList();
      return res.data.data;
    },
  });

  useEffect(() => {
    if (data && dataDetail) {
      methods.reset({
        id: dataDetail?._id,
        screening: dataDetail?.screening
          ? dataDetail?.screening
          : data?.map((item) => {
              return {
                screening_id: item.id,
                name: item?.nama,
                principle: item?.prinsip,
                selectedPrinciple: item?.prinsip?.map((child) => {
                  return {
                    label: `${child.nama} - ${child.deskripsi}`,
                    value: {
                      deskripsi: child.deskripsi,
                      id: child?.id,
                      idIsp: child?.idIsp,
                      nama: child?.nama,
                    },
                  };
                }),
              };
            }),
      });
    }
  }, [data, dataDetail]);

  const { fields } = useFieldArray({
    control,
    name: 'screening',
    keyName: '_id',
  });

  const onSubmit = (data) => {
    submitMutation.mutate(data);
  };

  const submitMutation = useMutation({
    mutationKey: ['post-safeguard-step-5'],
    mutationFn: async (payload) => {
      const res = await postSafeguardsStep5(payload);
      return res;
    },
    onSuccess: () => {
      onNext(type);
    },
  });

  return (
    <div className="flex flex-col gap-5">
      <AlertWarning
        children={
          <div>
            <p>Lengkapi Data</p>
            <small className="font-normal text-slate-700">
              Silahkan isi semua data dibawah ini untuk bisa melanjutkan
              pengisian ke tahap selanjutnya
            </small>
          </div>
        }
      />
      <FormProvider {...methods}>
        <CardForm label="Penapisan">
          {isLoading ? (
            <div className="w-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <>
              {fields?.map((item, index) => (
                <NestedScreening
                  key={item?._id}
                  item={item}
                  index={index}
                  alias={`screening.${index}`}
                  editable={editable}
                />
              ))}
            </>
          )}
        </CardForm>
        <BottomFormAction
          lastStep={false}
          backButtonAction={() => onBack()}
          disableDrafButton={false}
          draftLabel={'Kembali Ke Proposal'}
          drafButtonAction={() => {
            navigate(`/proposal/call-for-proposal/${id}`);
          }}
          isLoading={submitMutation.isLoading}
          // disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </div>
  );
};

const NestedScreening = ({ item, alias, index, editable }) => {
  const { setValue } = useFormContext();

  const radioValue = useWatch({
    name: `${alias}.answer`,
  });

  useEffect(() => {
    if (radioValue === 'no') {
      setValue(`${alias}.description`, '-');
    }
  }, [radioValue]);

  return (
    <div key={item?._id} className="flex flex-col gap-4">
      <QuestionBadge text={item?.name} />
      <InputFormRadio
        controllerName={`${alias}.answer`}
        disabled={!editable}
        values={[
          {
            label: 'Ya',
            value: 'yes',
          },
          {
            label: 'Tidak',
            value: 'no',
          },
        ]}
      />
      <AtomSelect
        className="basic-single mt-2"
        label={'Perlindungan lingkungan dan sosial yang terpicu'}
        required={true}
        controllerName={`${alias}.selectedPrinciple`}
        isMulti
        closeMenuOnSelect={false}
        disable={true}
      />
      <div className="flex gap-1  mb-2 flex-col">
        <span className={`label-text font-semibold text-[14px]`}>
          Deskripsi
        </span>
        <InputForm
          controllerName={`${alias}.description`}
          className={`py-2 px-4 border w-full rounded-md flex-1`}
          textArea={true}
          rows={5}
          disabled={radioValue === 'no' || !editable}
        />
      </div>
    </div>
  );
};

export default Screening;
