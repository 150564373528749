import React, { useEffect, useState } from 'react';
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from 'components';
import {
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import AtomSelect from 'components/atoms/Select';
import CardForm from 'components/molecules/CardForm';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiAddLine, RiDeleteBin6Line, RiQuestionLine } from 'react-icons/ri';
import AtomDatePicker from 'components/atoms/Datepicker';
import { isCitizenIdValid } from 'helpers/validation';
import { validationErrorMsg } from 'locale/yup_id';
import { useMutation } from '@tanstack/react-query';
import { fileUpload, getFile } from 'services/danaProgram/fileService';
import { getPositionService } from 'services/masterData/position';

const defaultValueObj = {
  identity_number: '',
  name: '',
  employee_status: null,
  dob: null,
  education: null,
  gender: null,
  position: '',
  work_experience: '',
  skills: '',
  url_file_education_certificate: [],
  url_file_resume: '',
};

const total_staff_program_option = [
  {
    label: 'Kurang Dari 10 Orang',
    value: 'Kurang Dari 10 Orang',
  },
  {
    label: '10-50 Orang',
    value: '10-50 Orang',
  },
  {
    label: '50-100 Orang',
    value: '50-100 Orang',
  },
  {
    label: 'Diatas 100 Orang',
    value: 'Diatas 100 Orang',
  },
];

const total_contract_employee_option = [
  {
    label: 'Tidak ada',
    value: 'Tidak ada',
  },
  {
    label: 'Kurang Dari 10 orang',
    value: 'Kurang Dari 10 orang',
  },
  {
    label: '11-30 orang',
    value: '11-30 orang',
  },
  {
    label: '31-100 orang',
    value: '31-100 orang',
  },
  {
    label: 'Diatas 100 orang',
    value: 'Diatas 100 orang',
  },
];

const total_employee_option = [
  {
    label: '1-3 Orang',
    value: '1-3 Orang',
  },
  {
    label: '4-7 Orang',
    value: '4-7 Orang',
  },
  {
    label: '8-15 Orang',
    value: '8-15 Orang',
  },
  {
    label: 'Diatas 15 orang',
    value: 'Diatas 15 orang',
  },
];

const validationSchema = yup.object({
  total_employee_full_institution: yup
    .mixed()
    .nonNullable()
    .label('total karyawan tetap lembaga wajib diisi'),
  total_employee_contract_institution: yup
    .mixed()
    .nonNullable()
    .label('total karyawan kontrak lembaga wajib diisi'),
  total_staff_institution: yup
    .mixed()
    .nonNullable()
    .label('total staff lembaga wajib diisi'),
  program_personnel: yup.array().of(
    yup.object().shape({
      identity_number: yup
        .string()
        .trim()
        .test(
          'identity_number',
          ({ label }) =>
            validationErrorMsg.mixed.default.replace('${path}', label),
          isCitizenIdValid
        )
        .required()
        .label('NIK'),
      name: yup.string().required(`nama wajib diisi`),
      employee_status: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string().required(`status kepegawaian wajib diisi`),
        })
        .required(`status kepegawaian wajib diisi`)
        .label('Status kepegawaian'),
      dob: yup.string().required('tanggal lahir wajib diisi'),
      education: yup
        .object({
          value: yup
            .string()
            .required(`Pendidikan wajib diisi`)
            .label('Pendidikan'),
        })
        .required(`Pendidikan wajib diisi`)
        .label('Pendidikan'),
      gender: yup.mixed().required('jenis kelamin wajib diisi'),
      position: yup
        .object()
        .typeError('Jabatan wajib diisi')
        .required('jabatan wajib diisi'),
      work_experience: yup
        .string()
        .required('pengalaman kerja wajib diisi'),
      skills: yup.string().required('keahlian wajib diisi'),
      url_file_education_certificate: yup.mixed(),
      url_file_resume: yup.mixed(),
    })
  ),
  finance_personnel: yup.array().of(
    yup.object().shape({
      identity_number: yup
        .string()
        .trim()
        .test(
          'identity_number',
          ({ label }) =>
            validationErrorMsg.mixed.default.replace('${path}', label),
          isCitizenIdValid
        )
        .required()
        .label('NIK'),
      name: yup.string().required(`nama wajib diisi`),
      employee_status: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string().required(`status kepegawaian wajib diisi`),
        })
        .required(`status kepegawaian wajib diisi`)
        .label('Status kepegawaian'),
      dob: yup.string().required('tanggal lahir wajib diisi'),
      education: yup
        .object({
          value: yup
            .string()
            .required(`Pendidikan wajib diisi`)
            .label('Pendidikan'),
        })
        .required(`Pendidikan wajib diisi`)
        .label('Pendidikan'),

      gender: yup.mixed().required('jenis kelamin wajib diisi'),
      position: yup
        .object()
        .typeError('Jabatan wajib diisi')
        .required('jabatan wajib diisi'),
      work_experience: yup
        .string()
        .required('pengalaman kerja wajib diisi'),
      skills: yup.string().required('keahlian wajib diisi'),
      url_file_education_certificate: yup.mixed(),
      url_file_resume: yup.mixed(),
    })
  ),
  safeguard_personnel: yup.array().of(
    yup.object().shape({
      identity_number: yup
        .string()
        .trim()
        .test(
          'identity_number',
          ({ label }) =>
            validationErrorMsg.mixed.default.replace('${path}', label),
          isCitizenIdValid
        )
        .required()
        .label('NIK'),
      name: yup.string().required(`nama wajib diisi`),
      employee_status: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string().required(`status kepegawaian wajib diisi`),
        })
        .required(`status kepegawaian wajib diisi`)
        .label('Status kepegawaian'),
      dob: yup.string().required('tanggal lahir wajib diisi'),
      education: yup
        .object({
          value: yup
            .string()
            .required(`Pendidikan wajib diisi`)
            .label('Pendidikan'),
        })
        .required(`Pendidikan wajib diisi`)
        .label('Pendidikan'),

      gender: yup.mixed().required('jenis kelamin wajib diisi'),
      position: yup
        .object()
        .typeError('Jabatan wajib diisi')
        .required('jabatan wajib diisi'),
      work_experience: yup
        .string()
        .required('pengalaman kerja wajib diisi'),
      skills: yup.string().required('keahlian wajib diisi'),
      url_file_education_certificate: yup.mixed(),
      url_file_resume: yup.mixed(),
    })
  ),
  other_personnel: yup.array().of(
    yup.object().shape({
      identity_number: yup
        .string()
        .trim()
        .test(
          'identity_number',
          ({ label }) =>
            validationErrorMsg.mixed.default.replace('${path}', label),
          isCitizenIdValid
        )
        .label('NIK'),
    })
  ),
  work_performance: yup.array().of(
    yup.object().shape({
      type_work_performance: yup
        .object({
          value: yup.string().required(`tipe wajib diisi`),
        })
        .required('tipe wajib diisi')
        .label('tipe'),
      reward: yup.string().required(`reward wajib diisi`),
    })
  ),
  document_support: yup.object().shape({
    description_job_description: yup
      .string()
      .required('uraian singkat terkait isi dokumen wajib diisi'),
    description_structure_institution: yup
      .string()
      .required('uraian singkat terkait isi dokumen wajib diisi'),
  }),
  punishment: yup.array().of(
    yup.object().shape({
      type_punishment: yup.string().required(`tipe wajib diisi`),
      consequence: yup.string().required(`konsekwensi wajib diisi`),
    })
  ),
});

const SumberdayaManusia = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [controllerTarget, setControllerTarget] = useState();

  const [uploadedUrls1, setUploadedUrls1] = useState([]);
  const [controllerTarget1, setControllerTarget1] = useState();

  const [uploadedUrls3, setUploadedUrls3] = useState([]);
  const [controllerTarget3, setControllerTarget3] = useState();

  const [positions, setPositions] = useState();

  const personnelDefaultValues = [
    {
      identity_number: '',
      name: '',
      employee_status: undefined,
      dob: null,
      education: null,
      position: '',
      work_experience: '',
      skills: '',
      url_file_education_certificate: null,
      url_file_resume: null,
    },
  ];

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      program_personnel: [defaultValueObj],
      finance_personnel: [defaultValueObj],
      safeguard_personnel: [defaultValueObj],
      other_personnel: [defaultValueObj],
    }
  });

  const {
    formState: { errors, isValid },
    control,
    setValue,
  } = methods;

  const {
    fields: programPersonnelFields,
    append: appendProgramPersonnels,
    remove: removeProgramPersonnels,
  } = useFieldArray({
    control,
    name: 'program_personnel',
  });

  const {
    fields: safeguardPersonnelFields,
    append: appendSafeguardPersonnels,
    remove: removeSafeguardPersonnels,
  } = useFieldArray({
    control,
    name: 'safeguard_personnel',
  });

  const {
    fields: anotherPersonnelFields,
    append: appendAnotherPersonnels,
    remove: removeAnotherPersonnels,
  } = useFieldArray({
    control,
    name: 'other_personnel',
  });

  const {
    fields: financePersonnelFields,
    append: appendFinancePersonnels,
    remove: removeFinancePersonnels,
  } = useFieldArray({
    control,
    name: 'finance_personnel',
  });

  const {
    fields: rewardFields,
    append: appendRewards,
    remove: removeRewards,
  } = useFieldArray({
    control,
    name: 'work_performance',
  });

  const {
    fields: punishmentFields,
    append: appendPunishments,
    remove: removePunishments,
  } = useFieldArray({
    control,
    name: 'punishment',
  });

  useEffect(() => {
    if (draftData?.document_support || draftData?.partner_list) {
      Object.keys(draftData).forEach((key, index) => {
        if (key === 'partner_list') {
          Object.keys(draftData[key]).forEach((obj, i) => {
            if (obj.includes('personnel')) {
              if (draftData[key][obj].length > 0) {
                draftData[key][obj].map((data, index) => {
                  Object.keys(draftData[key][obj][index]).forEach((dat) => {
                    if (dat === 'employee_status') {
                      if (draftData[key][obj][index][dat] === '') {
                        setValue(`${obj}.${index}.${dat}`, null);
                      }
                    }
                    if (dat === 'education') {
                      if (draftData[key][obj][index][dat] === '') {
                        setValue(`${obj}.${index}.${dat}`, null);
                      }
                    }
                    if (dat === 'url_file_resume') {
                      if (draftData[key][obj][index][dat] !== '') {
                        getFileMutation.mutate({
                          id: draftData[key][obj][index][dat],
                          key: `${obj}.${index}.${dat}`,
                          single: true,
                        });
                      }
                    } else if (
                      dat === 'url_file_education_certificate' &&
                      Array.isArray(draftData[key][obj][index][dat])
                    ) {
                      draftData[key][obj][index][dat].forEach((element) => {
                        getFileMutation.mutate({
                          id: element?.id,
                          key: `${obj}.${index}.${dat}`,
                          single: false,
                        });
                      });
                      setValue(
                        `${obj}.${index}.url_file_education_certificate`,
                        draftData[key][obj][index][dat]
                      );
                    } else {
                      setValue(
                        `${obj}.${index}.${dat}`,
                        draftData[key][obj][index][dat]
                      );
                    }
                  });
                });
              } else {
                setValue(`${obj}`, personnelDefaultValues);
              }
            } else if (obj.includes('total')) {
              setValue(`${obj}`, {
                label: draftData[key][obj],
                value: draftData[key][obj],
              });

              setValue(`${obj}`, draftData[key][obj]);
            } else if (obj === 'work_performance') {
              if (draftData[key][obj].length !== 0) {
                draftData[key][obj].map((item) => {
                  if (rewardFields.length === 0) {
                    appendRewards({
                      type_work_performance: item.type_work_performance,
                      reward: item.reward,
                    });
                  }
                });
              } else {
                appendRewards({
                  type_work_performance: '',
                  reward: '',
                });
              }
            } else if (obj === 'punishment') {
              if (draftData[key][obj].length !== 0) {
                draftData[key][obj].map((item) => {
                  if (punishmentFields.length === 0) {
                    appendPunishments({
                      type_punishment: item.type_punishment,
                      consequence: item.consequence,
                    });
                  }
                });
              } else {
                appendPunishments({
                  type_punishment: '',
                  consequence: '',
                });
              }
            } else {
              setValue(obj, draftData[key][obj]);
            }
          });
        }

        if (key === 'document_support') {
          Object.keys(draftData[key]).forEach((data, index) => {
            if (
              data === 'url_file_job_description' ||
              data === 'url_file_structure_institution'
            ) {
              getFileMutation.mutate({
                id: draftData[key][data],
                key: `document_support.${data}`,
                single: true,
              });
            } else {
              setValue(`document_support.${data}`, draftData[key][data]);
            }
          });
        }
      });
    }
  }, [draftData]);

  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response?.data?.data?.url,
        id: response?.data?.data?.id,
        type: response?.data?.data?.mimeType,
        name: response?.data?.data?.name,
        size: response?.data?.data?.size,
      };
      setValue(file?.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key, single }) => {
    try {
      if (id == null) return null;
      const response = await getFile(id);
      let temp = {
        url: response?.data?.data?.url,
        id: response?.data?.data?.id,
        type: response?.data?.data?.mimeType,
        name: response?.data?.data?.name,
        size: response?.data?.data?.size,
      };
      if (single) {
        setValue(key, temp);
      } else {
        return temp;
      }
    } catch (error) {
      console.log(error);
    }
  });

  const getPosition = useMutation({
    mutationFn: async () => {
      try {
        const positionResponse = await getPositionService();
        const transformedData = positionResponse?.data?.data?.map(
          (item, index) => ({
            label: item.nama,
            value: item.id,
          })
        );
        setPositions(transformedData);
      } catch (error) {
        throw error;
      }
    },
  });

  useEffect(() => {
    getPosition.mutate();
  }, []);

  return (
    <FormProvider {...methods}>
      <>
        <CardForm label={'Daftar Rekanan/Mitra'}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    'Berapa jumlah karyawaan tetap dari Lembaga/institusi/perusahaan Anda saat ini?'
                  }
                  controllerName={'total_employee'}
                  values={total_employee_option}
                  disabled={isReadonly}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    'Berapa jumlah karyawan kontrak dari lembaga/ institusi/ perusahaan Anda?'
                  }
                  controllerName={'total_contract_employee'}
                  values={total_contract_employee_option}
                  disabled={isReadonly}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    'Berapa banyak staf yang lembaga/ institusi/ perusahaan Anda alokasikan ketika menjalankan suatu program?'
                  }
                  controllerName={'total_staff_program'}
                  values={total_staff_program_option}
                  disabled={isReadonly}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg">
                <RiQuestionLine />
                Bagaimana susunan komposisinya?
              </div>
            </div>
            {/* Section */}
            <span className="font-semibold mt-4">Personil Program</span>
            <CardForm
              label={'Personil 1'}
              buttonCollapse={true}
              childerForm={true}
            >
              <>
                {methods.getValues('program_personnel')?.length > 0
                  ? methods
                    .getValues('program_personnel')
                    .map((data, index) => (
                      <ProgramPersonnelForm
                        data={data}
                        index={index}
                        setValue={setValue}
                        controllerTarget={controllerTarget}
                        uploadedUrls={uploadedUrls}
                        errors={errors}
                        positions={positions}
                        setUploadedUrls={setUploadedUrls}
                        setControllerTarget={setControllerTarget}
                        mutation={mutation}
                        methods={methods}
                        removeProgramPersonnels={removeProgramPersonnels}
                      />
                    ))
                  : ''}
              </>
            </CardForm>
            <div className="border"></div>
            {
              isReadonly ? null : (
                <div className="flex flex-row-reverse my-5 mx-4 ">
                  <Button
                    className="p-4 border rounded-lg"
                    label={
                      <div className="flex items-center gap-2 font-[14px]">
                        <RiAddLine />
                        Tambah Data Personil Program
                      </div>
                    }
                    onClick={() => appendProgramPersonnels(defaultValueObj)}
                  />
                </div>
              )
            }


            {/* Section */}
            <div className="border"></div>
            <span className="font-semibold mt-4">Personil Keuangan</span>
            <CardForm
              label={'Personil 1'}
              buttonCollapse={true}
              childerForm={true}
            >
              <>
                {methods.getValues('finance_personnel')?.length > 0
                  ? methods
                    .getValues('finance_personnel')
                    ?.map((data, index) => (
                      <FinancePersonnelForm
                        data={data}
                        index={index}
                        setValue={setValue}
                        controllerTarget1={controllerTarget1}
                        uploadedUrls1={uploadedUrls1}
                        errors={errors}
                        positions={positions}
                        setUploadedUrls1={setUploadedUrls1}
                        setControllerTarget1={setControllerTarget1}
                        mutation={mutation}
                        methods={methods}
                        removeFinancePersonnels={removeFinancePersonnels}
                      />
                    ))
                  : ''}
              </>
            </CardForm>
            {
              isReadonly ? null : (
                <div className="border"></div>
              )
            }
            {
              isReadonly ? null : (
                <div className="flex flex-row-reverse my-5 mx-4 ">
                  <Button
                    className="p-4 border rounded-lg"
                    label={
                      <div className="flex items-center gap-2 font-[14px]">
                        <RiAddLine />
                        Tambah Data Personil Keuangan
                      </div>
                    }
                    onClick={() => appendFinancePersonnels(defaultValueObj)}
                  />
                </div>
              )
            }

            {/* Section */}
            <div className="border"></div>
            <span className="font-semibold mt-4">Personil Safeguard</span>
            <CardForm
              label={'Personil 1'}
              buttonCollapse={true}
              childerForm={true}
            >
              <>
                {methods.getValues('safeguard_personnel')?.length > 0
                  ? methods
                    .getValues('safeguard_personnel')
                    ?.map((data, index) => (
                      <SafeguardPersonnelForm
                        data={data}
                        index={index}
                        setValue={setValue}
                        controllerTarget1={controllerTarget1}
                        uploadedUrls1={uploadedUrls1}
                        errors={errors}
                        positions={positions}
                        setUploadedUrls1={setUploadedUrls1}
                        setControllerTarget1={setControllerTarget1}
                        mutation={mutation}
                        methods={methods}
                        removeFinancePersonnels={removeFinancePersonnels}
                      />
                    ))
                  : ''}
              </>
            </CardForm>
            {
              isReadonly ? null : (
                <div className="border"></div>
              )
            }
            {
              isReadonly ? null : (
                <div className="flex flex-row-reverse my-5 mx-4 ">
                  <Button
                    className="p-4 border rounded-lg"
                    label={
                      <div className="flex items-center gap-2 font-[14px]">
                        <RiAddLine />
                        Tambah Data Personil Safeguard
                      </div>
                    }
                    onClick={() => appendSafeguardPersonnels(defaultValueObj)}
                  />
                </div>
              )
            }

            {/* Section */}
            <div className="border"></div>
            <span className="font-semibold mt-4">Personil Lain - lain</span>
            <CardForm
              label={'Personil 1'}
              buttonCollapse={true}
              childerForm={true}
            >
              <>
                {methods.getValues('other_personnel')?.length > 0
                  ? methods
                    .getValues('other_personnel')
                    ?.map((data, index) => (
                      <OtherPersonnelForm
                        data={data}
                        index={index}
                        setValue={setValue}
                        controllerTarget3={controllerTarget3}
                        uploadedUrls3={uploadedUrls3}
                        errors={errors}
                        positions={positions}
                        mutation={mutation}
                        methods={methods}
                        setUploadedUrls3={setUploadedUrls3}
                        setControllerTarget3={setControllerTarget3}
                        removeAnotherPersonnels={removeAnotherPersonnels}
                      />
                    ))
                  : ''}
              </>
            </CardForm>
            <div className="border"></div>
            {
              isReadonly ? null : (
                <div className="flex flex-row-reverse my-5 mx-4 ">
                  <Button
                    className="p-4 border rounded-lg"
                    label={
                      <div className="flex items-center gap-2 font-[14px]">
                        <RiAddLine />
                        Tambah Data Personil Safeguard
                      </div>
                    }
                    onClick={() => appendAnotherPersonnels(defaultValueObj)}
                  />
                </div>
              )
            }

            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg">
                <RiQuestionLine />
                Ketika karyawan/staf Anda meraih prestasi kerja, bentuk
                apresiasi (reward) seperti apa yang lembaga/instansi/perusahaan
                Anda berikan?
              </div>
            </div>
            <section>
              {rewardFields.length > 0
                ? rewardFields.map((data, index) => (
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <AtomSelect
                          controllerName={`work_performance.${index}.type_work_performance`}
                          options={[
                            {
                              value: 'Karyawan Teladan',
                              label: 'Karyawan Teladan',
                            },
                            {
                              value: 'Karyawan dengan Target Tertinggi',
                              label: 'Karyawan dengan Target Tertinggi',
                            },
                            {
                              value: 'Karyawan Tepat Waktu',
                              label: 'Karyawan Tepat Waktu',
                            },
                            {
                              value: 'Karyawan yang Sering Menolong',
                              label: 'Karyawan yang Sering Menolong',
                            },
                          ]}
                          className="basic-single mt-2"
                          label="Jenis Prestasi Kerja"
                          required={true}
                          disable={isReadonly}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 items-end pt-2" key={data.id}>
                      <div className="flex gap-4 w-full">
                        <div className="flex-1 pt-2">
                          <InputForm
                            controllerName={`work_performance.${index}.reward`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara
                              ? 'border-[#F04438]'
                              : 'border-green'
                              }`}
                            label={'Reward yang diberikan'}
                            placeholder={'Reward yang diberikan'}
                            required={true}
                            disabled={isReadonly}
                          />
                        </div>
                      </div>
                      {index !== 0 && !isReadonly && (
                        <button
                          className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                          onClick={() => removeRewards(index)}
                        >
                          <RiDeleteBin6Line />
                        </button>
                      )}
                    </div>
                  </>
                ))
                : ''}
              {
                isReadonly ? null : (
                  <div className="flex flex-row-reverse my-5 mx-4 ">
                    <Button
                      className="p-4 border rounded-lg"
                      label={
                        <div className="flex items-center gap-2 font-[14px]">
                          <RiAddLine />
                          Tambah Prestasi Kerja
                        </div>
                      }
                      onClick={() => {
                        appendRewards({
                          type_work_performance: '',
                          reward: '',
                        });
                      }}
                    />
                  </div>

                )
              }
            </section>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg">
                <RiQuestionLine />
                Ketika karyawan/ staf lembaga/ institusi/ perusahaan Anda
                melakukan kesalahan/ pelanggaran kerja, apa yang menjadi
                konsekwensi (punishment) yang diberikan kepada karyawan/ staf
                Anda.
              </div>
            </div>
            <section>
              {punishmentFields.length > 0 &&
                punishmentFields.map((data, index) => (
                  <div
                    className="flex gap-4 items-end border-t pt-2"
                    key={data.id}
                  >
                    <div
                      className={`flex-1 pt-2 ${index === 0 ? 'w-1/2' : 'w-full'
                        }`}
                    >
                      <div className="flex gap-4">
                        <div className="w-1/2">
                          <InputForm
                            controllerName={`punishment.${index}.type_punishment`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md ${errors?.punishment?.[index]?.type
                              ? 'border-[#F04438]'
                              : 'border-green'
                              }`}
                            label={'Jenis Kesalahan/Pelanggaran'}
                            placeholder={'Jenis Kesalahan/Pelanggaran'}
                            required={true}
                            disabled={isReadonly}
                          />
                        </div>
                        <div className="w-1/2">
                          <InputForm
                            controllerName={`punishment.${index}.consequence`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md ${errors?.punishment?.[index]?.name
                              ? 'border-[#F04438]'
                              : 'border-green'
                              }`}
                            label={'Konsekwensi/Punishment yang diberikan'}
                            placeholder={
                              'Konsekwensi/Punishment yang diberikan'
                            }
                            required={true}
                            disabled={isReadonly}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-11 h-11 flex items-center justify-center">
                      {index !== 0 && !isReadonly && (
                        <button
                          className={`p-3 bg-[#D92D20] text-white rounded-md`}
                          onClick={() => removePunishments(index)}
                        >
                          <RiDeleteBin6Line />
                        </button>
                      )}
                    </div>
                  </div>
                ))}

              {
                isReadonly ? null : (
                  <div className="flex flex-row-reverse my-5 mx-4 ">
                    <Button
                      className="p-4 border rounded-lg"
                      label={
                        <div className="flex items-center gap-2 font-[14px]">
                          <RiAddLine />
                          Tambah Pelanggaran Kerja
                        </div>
                      }
                      onClick={() => {
                        appendPunishments({
                          type_punishment: '',
                          consequence: '',
                        });
                      }}
                    />
                  </div>

                )
              }
            </section>
            <CardForm
              label={'Lembaga 1'}
              buttonCollapse={true}
              childerForm={true}
            >
              <>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      controllerName={`document_support.url_file_structure_institution`}
                      label={
                        'Dokumen struktur organisasi Lembaga/Instansi/Project'
                      }
                      uploadFile={mutation}
                      methods={methods}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={
                        'document_support.description_structure_institution'
                      }
                      className={`py-2 px-4 mt-2 h-32 border w-full rounded-md ${errors.nama_lemtara
                        ? 'border-[#F04438]'
                        : 'border-green'
                        }`}
                      label={'Uraian singkat terkait isi dokumen'}
                      placeholder={'Uraian singkat terkait isi dokumen'}
                      required={true}
                      textArea={true}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      controllerName={`document_support.url_file_job_description`}
                      label={'Dokumen fungsi organ (job descriptions)'}
                      uploadFile={mutation}
                      methods={methods}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={
                        'document_support.description_job_description'
                      }
                      className={`py-2 px-4 mt-2 h-32 border w-full rounded-md ${errors.nama_lemtara
                        ? 'border-[#F04438]'
                        : 'border-green'
                        }`}
                      label={'Uraian singkat terkait isi dokumen'}
                      placeholder={'Uraian singkat terkait isi dokumen'}
                      required={true}
                      textArea={true}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
              </>
            </CardForm>
          </>
        </CardForm>

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          hideDraft={isReadonly}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={methods.handleSubmit(onSubmit)}
        />
      </>
    </FormProvider>
  );
};

const ProgramPersonnelForm = ({
  data,
  index,
  setValue,
  controllerTarget,
  uploadedUrls,
  errors,
  positions,
  setUploadedUrls,
  setControllerTarget,
  mutation,
  methods,
  removeProgramPersonnels,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";

  return (
    <div className="flex gap-4 items-end border-t pt-2" key={data.id}>
      <div className="w-full">
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`program_personnel.${index}.identity_number`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'NIK'}
              placeholder={'NIK'}
              required={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`program_personnel.${index}.employee_status`}
              className={`mt-2 w-full rounded-md `}
              options={[
                {
                  value: 'Karyawan Tetap',
                  label: 'Karyawan tetap',
                },
                {
                  value: 'Karyawan Kontrak',
                  label: 'Karyawan kontrak',
                },
              ]}
              label={'Status Kepegawaian'}
              placeholder={'Status Kepegawaian'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`program_personnel.${index}.name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Nama'}
              placeholder={'Nama'}
              required={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`program_personnel.${index}.gender`}
              className={`mt-2 w-full rounded-md ${errors[`program_personnel.${index}.gender`]
                ? 'border-[#F04438]'
                : 'border-green'
                }`}
              options={[
                { value: 'L', label: 'Laki-laki' },
                { value: 'P', label: 'Perempuan' },
              ]}
              label={'Jenis Kelamin'}
              placeholder={'Jenis Kelamin'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomDatePicker
              newDate={true}
              controllerName={`program_personnel.${index}.dob`}
              label="Tanggal Lahir"
              required={true}
              customPicker={true}
              showErrorLabel={true}
              asText={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`program_personnel.${index}.education`}
              className={`mt-2 w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              options={[
                { value: 'S1', label: 'S1' },
                { value: 'S2', label: 'S2' },
                { value: 'S3', label: 'S3' },
              ]}
              label={'Pendidikan'}
              placeholder={'Pendidikan'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`program_personnel.${index}.position`}
              className={`basic-single mt-2 ${errors.position ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Jabatan Dalam Proyek'}
              placeholder={'Jabatan Dalam Proyek'}
              required={true}
              options={positions ? positions : []}
              disable={isReadonly}
            />
          </div>

          <div className="flex-1 pt-2"></div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`program_personnel.${index}.work_experience`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Pengalaman Kerja'}
              placeholder={'Pengalaman Kerja'}
              required={true}
              textArea={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`program_personnel.${index}.skills`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Keahlian'}
              placeholder={'Keahlian'}
              textArea={true}
              required={true}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`program_personnel.${index}.url_file_education_certificate`}
              label={'Sertifikat/Ijazah *Upload Multiple'}
              isMulti={true}
              isDaprog={true}
              setUploadedUrls={setUploadedUrls}
              setControllerTarget={setControllerTarget}
              uploadFile={mutation}
              methods={methods}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`program_personnel.${index}.url_file_resume`}
              label={'CV/Resume'}
              uploadFile={mutation}
              methods={methods}
              maxFiles={1}
              informationText="PNG, JPG, PDF, Docx"
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  [],
              }}
              disabled={isReadonly}
            />
          </div>
        </div>
      </div>
      {index !== 0 && (
        <button
          className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
          onClick={() => removeProgramPersonnels(index)}
        >
          <RiDeleteBin6Line />
        </button>
      )}
    </div>
  );
};

const FinancePersonnelForm = ({
  data,
  index,
  setValue,
  controllerTarget1,
  uploadedUrls1,
  errors,
  positions,
  setUploadedUrls1,
  setControllerTarget1,
  mutation,
  methods,
  removeFinancePersonnels,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";

  return (
    <div className="flex gap-4 items-end border-t pt-2" key={data.id}>
      <div className="w-full">
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`finance_personnel.${index}.identity_number`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'NIK'}
              placeholder={'NIK'}
              required={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`finance_personnel.${index}.employee_status`}
              className={`mt-2 w-full rounded-md `}
              options={[
                {
                  value: 'Karyawan Tetap',
                  label: 'Karyawan tetap',
                },
                {
                  value: 'Karyawan Kontrak',
                  label: 'Karyawan kontrak',
                },
              ]}
              label={'Status Kepegawaian'}
              placeholder={'Status Kepegawaian'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`finance_personnel.${index}.name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Nama'}
              placeholder={'Nama'}
              required={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`finance_personnel.${index}.gender`}
              className={`mt-2 w-full rounded-md ${errors[`finance_personnel.${index}.gender`]
                ? 'border-[#F04438]'
                : 'border-green'
                }`}
              options={[
                { value: 'L', label: 'Laki-laki' },
                { value: 'P', label: 'Perempuan' },
              ]}
              label={'Jenis Kelamin'}
              placeholder={'Jenis Kelamin'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomDatePicker
              newDate={true}
              controllerName={`finance_personnel.${index}.dob`}
              label="Tanggal Lahir"
              showErrorLabel={true}
              required={true}
              customPicker={true}
              asText={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`finance_personnel.${index}.education`}
              className={`mt-2 w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              options={[
                { value: 'S1', label: 'S1' },
                { value: 'S2', label: 'S2' },
                { value: 'S3', label: 'S3' },
              ]}
              label={'Pendidikan'}
              placeholder={'Pendidikan'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`finance_personnel.${index}.position`}
              className={`basic-single mt-2 ${errors.position ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Jabatan Dalam Proyek'}
              placeholder={'Jabatan Dalam Proyek'}
              required={true}
              options={positions ? positions : []}
              disable={isReadonly}
            />
          </div>

          <div className="flex-1 pt-2"></div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`finance_personnel.${index}.work_experience`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Pengalaman Kerja'}
              placeholder={'Pengalaman Kerja'}
              required={true}
              textArea={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`finance_personnel.${index}.skills`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Keahlian'}
              placeholder={'Keahlian'}
              textArea={true}
              required={true}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`finance_personnel.${index}.url_file_education_certificate`}
              label={'Sertifikat/Ijazah *Upload Multiple'}
              isMulti={true}
              isDaprog={true}
              setUploadedUrls={setUploadedUrls1}
              setControllerTarget={setControllerTarget1}
              uploadFile={mutation}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`finance_personnel.${index}.url_file_resume`}
              label={'CV/Resume'}
              uploadFile={mutation}
              methods={methods}
              maxFiles={1}
              informationText="PNG, JPG, PDF, Docx"
              disabled={isReadonly}
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  [],
              }}
            />
          </div>
        </div>
      </div>
      {index !== 0 && !isReadonly && (
        <button
          className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
          onClick={() => removeFinancePersonnels(index)}
        >
          <RiDeleteBin6Line />
        </button>
      )}
    </div>
  );
};

const SafeguardPersonnelForm = ({
  data,
  index,
  setValue,
  controllerTarget1,
  uploadedUrls1,
  errors,
  positions,
  setUploadedUrls1,
  setControllerTarget1,
  mutation,
  methods,
  removeFinancePersonnels,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";

  return (
    <div className="flex gap-4 items-end border-t pt-2" key={data.id}>
      <div className="w-full">
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`safeguard_personnel.${index}.identity_number`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'NIK'}
              placeholder={'NIK'}
              required={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`safeguard_personnel.${index}.employee_status`}
              className={`mt-2 w-full rounded-md `}
              options={[
                {
                  value: 'Karyawan Tetap',
                  label: 'Karyawan tetap',
                },
                {
                  value: 'Karyawan Kontrak',
                  label: 'Karyawan kontrak',
                },
              ]}
              label={'Status Kepegawaian'}
              placeholder={'Status Kepegawaian'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`safeguard_personnel.${index}.name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Nama'}
              placeholder={'Nama'}
              required={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`safeguard_personnel.${index}.gender`}
              className={`mt-2 w-full rounded-md ${errors[`safeguard_personnel.${index}.gender`]
                ? 'border-[#F04438]'
                : 'border-green'
                }`}
              options={[
                { value: 'L', label: 'Laki-laki' },
                { value: 'P', label: 'Perempuan' },
              ]}
              label={'Jenis Kelamin'}
              placeholder={'Jenis Kelamin'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomDatePicker
              newDate={true}
              controllerName={`safeguard_personnel.${index}.dob`}
              label="Tanggal Lahir"
              showErrorLabel={true}
              required={true}
              customPicker={true}
              asText={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`safeguard_personnel.${index}.education`}
              className={`mt-2 w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              options={[
                { value: 'S1', label: 'S1' },
                { value: 'S2', label: 'S2' },
                { value: 'S3', label: 'S3' },
              ]}
              label={'Pendidikan'}
              placeholder={'Pendidikan'}
              required={true}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`safeguard_personnel.${index}.position`}
              className={`basic-single mt-2 ${errors.position ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Jabatan Dalam Proyek'}
              placeholder={'Jabatan Dalam Proyek'}
              required={true}
              options={positions ? positions : []}
              disable={isReadonly}
            />
          </div>

          <div className="flex-1 pt-2"></div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`safeguard_personnel.${index}.work_experience`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Pengalaman Kerja'}
              placeholder={'Pengalaman Kerja'}
              required={true}
              textArea={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`safeguard_personnel.${index}.skills`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Keahlian'}
              placeholder={'Keahlian'}
              textArea={true}
              required={true}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`safeguard_personnel.${index}.url_file_education_certificate`}
              label={'Sertifikat/Ijazah *Upload Multiple'}
              isMulti={true}
              isDaprog={true}
              setUploadedUrls={setUploadedUrls1}
              setControllerTarget={setControllerTarget1}
              uploadFile={mutation}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`safeguard_personnel.${index}.url_file_resume`}
              label={'CV/Resume'}
              uploadFile={mutation}
              methods={methods}
              maxFiles={1}
              informationText="PNG, JPG, PDF, Docx"
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  [],
              }}
              disabled={isReadonly}
            />
          </div>
        </div>
      </div>
      {index !== 0 && !isReadonly && (
        <button
          className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
          onClick={() => removeFinancePersonnels(index)}
        >
          <RiDeleteBin6Line />
        </button>
      )}
    </div>
  );
};

const OtherPersonnelForm = ({
  data,
  index,
  setValue,
  controllerTarget3,
  uploadedUrls3,
  errors,
  positions,
  mutation,
  methods,
  setUploadedUrls3,
  setControllerTarget3,
  removeAnotherPersonnels,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";

  return (
    <div className="flex gap-4 items-end border-t pt-2" key={data.id}>
      <div className="w-full">
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`other_personnel.${index}.identity_number`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'NIK'}
              placeholder={'NIK'}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`other_personnel.${index}.employee_status`}
              className={`mt-2 w-full rounded-md `}
              options={[
                {
                  value: 'Karyawan Tetap',
                  label: 'Karyawan tetap',
                },
                {
                  value: 'Karyawan Kontrak',
                  label: 'Karyawan kontrak',
                },
              ]}
              label={'Status Kepegawaian'}
              placeholder={'Status Kepegawaian'}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`other_personnel.${index}.name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Nama'}
              placeholder={'Nama'}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`other_personnel.${index}.gender`}
              className={`mt-2 w-full rounded-md ${errors[`safeguard_personnel.${index}.gender`]
                ? 'border-[#F04438]'
                : 'border-green'
                }`}
              options={[
                { value: 'L', label: 'Laki-laki' },
                { value: 'P', label: 'Perempuan' },
              ]}
              label={'Jenis Kelamin'}
              placeholder={'Jenis Kelamin'}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomDatePicker
              newDate={true}
              controllerName={`other_personnel.${index}.dob`}
              showErrorLabel={true}
              label="Tanggal Lahir"
              customPicker={true}
              asText={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`other_personnel.${index}.education`}
              className={`mt-2 w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              options={[
                { value: 'S1', label: 'S1' },
                { value: 'S2', label: 'S2' },
                { value: 'S3', label: 'S3' },
              ]}
              label={'Pendidikan'}
              placeholder={'Pendidikan'}
              disable={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`other_personnel.${index}.position`}
              className={`basic-single mt-2 ${errors.position ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Jabatan Dalam Proyek'}
              placeholder={'Jabatan Dalam Proyek'}
              options={positions ? positions : []}
              disable={isReadonly}
            />
          </div>

          <div className="flex-1 pt-2"></div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`other_personnel.${index}.work_experience`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Pengalaman Kerja'}
              placeholder={'Pengalaman Kerja'}
              textArea={true}
              disabled={isReadonly}
            />
          </div>
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`other_personnel.${index}.skills`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                }`}
              label={'Keahlian'}
              placeholder={'Keahlian'}
              textArea={true}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`other_personnel.${index}.url_file_education_certificate`}
              label={'Sertifikat/Ijazah *Upload Multiple'}
              isMulti={true}
              isDaprog={true}
              setUploadedUrls={setUploadedUrls3}
              setControllerTarget={setControllerTarget3}
              uploadFile={mutation}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              controllerName={`other_personnel.${index}.url_file_resume`}
              label={'CV/Resume'}
              uploadFile={mutation}
              methods={methods}
              maxFiles={1}
              informationText="PNG, JPG, PDF, Docx"
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  [],
              }}
              disabled={isReadonly}
            />
          </div>
        </div>
      </div>
      {index !== 0 && !isReadonly && (
        <button
          className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
          onClick={() => removeAnotherPersonnels(index)}
        >
          <RiDeleteBin6Line />
        </button>
      )}
    </div>
  );
};

export default SumberdayaManusia;
