import classNames from "classnames";
import { Button, InputFormRadio } from "components";
import FileInfoField from "components/atoms/FileInfoField";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { formatCurrency } from "helpers";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import { getUrlFile } from "services/danaProgram/fileService";
import { getDetailNoteConcept } from "services/danaProgram/noteConcept";
import _ from "lodash";

const KonsepNotesDetail = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("menunggu persetujuan");
  const [detailNoteConcept, setDetailNoteConcept] = useState(null);
  const params = useParams();
  const { id } = params || {};

  const onDownloadFileHandler = async (file) => {
    const response = await getUrlFile({
      identifier: "id",
      value: file?.fileId,
    });
    const link = await document.createElement("a");

    link.href = await response?.data?.data?.url;
    link.target = "_blank";
    link.setAttribute("download", file?.fileName);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    id && fetchDetailNoteConcept(id);
  }, [id]);

  const fetchDetailNoteConcept = async (id) => {
    const response = await getDetailNoteConcept(id);
    const data = await response?.data?.data;

    setDetailNoteConcept(data);
  };

  const items = [
    {
      label: "Daftar Permohonan Konsep Notes",
      path: "/proposal/konsep-notes",
    },
    { label: "Detail Konsep Note" },
  ];

  const renderStatusLabel = (status) => {
    return (
      <div
        className={classNames(
          [`w-fit py-1 px-2 rounded-[25px] flex gap-[1px] items-center`],
          { "bg-warning-50": status === "menunggu persetujuan" },
          { "bg-primary-50": status === "disetujui" },
          { "bg-error-200": status === "ditolak" }
        )}
      >
        <div
          className={classNames(
            [" font-medium text-xs capitalize"],
            { "text-warning-700": status === "menunggu persetujuan" },
            { "text-primary-700": status === "disetujui" },
            { "text-secondary-orange-700": status === "ditolak" }
          )}
        >
          {status}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
      {/* Header Section */}
      <div className="flex flex-col gap-4">
        <BreadCrumbs routes={items} />

        <h1 className="text-gray-900 text-3xl font-medium">Detail</h1>

        <hr />
      </div>

      {/* Detail Section */}
      <GeneralInformation
        project_name={detailNoteConcept?.project_name}
        proposal_title={detailNoteConcept?.proposal_title}
        proposal_description={detailNoteConcept?.description_proposal}
        status={renderStatusLabel(detailNoteConcept?.status.toLowerCase())}
        main_activity={detailNoteConcept?.main_activity}
        goals_activity={detailNoteConcept?.activity_goals}
        outcome={detailNoteConcept?.outcome}
        output={detailNoteConcept?.output}
      />

      {/* Notes: 
       1. Define params sesuai kebutuhan (bisa liat contoh di section GeneralInformation) 
       2. You can separate section into other files if you want better readable code
      */}

      <ActivityLocation
        locations={detailNoteConcept?.location}
        is_conversion_area={detailNoteConcept?.is_conversion_area}
        location_conversion_area={detailNoteConcept?.location_conversion_area}
        already_have_permission={detailNoteConcept?.already_have_permission}
      />

      <BeneficiariesInformation
        total_beneficiaries={detailNoteConcept?.total_beneficiaries}
        is_involve_indigenous_comunities={
          detailNoteConcept?.is_involve_indigenous_comunities
        }
        comunities_name={detailNoteConcept?.comunities_name}
        is_involve_beneficiaries_from_woman={
          detailNoteConcept?.is_involve_beneficiaries_from_woman
        }
        file_beneficiaries_document={
          detailNoteConcept?.file_beneficiaries_document
        }
        onDownloadFileHandler={(file) => onDownloadFileHandler(file)}
      />

      <EnvironmentalAndSafeguardInformation
        safeGuards={detailNoteConcept?.safeGuards}
      />

      <BudgetPlan
        fund_application_value={detailNoteConcept?.fund_application_value}
        rab_documents={detailNoteConcept?.rab_documents}
        onDownloadFileHandler={(file) => onDownloadFileHandler(file)}
      />

      <ApplicantInformation
        applicant_information={detailNoteConcept?.applicant_information}
      />

      {detailNoteConcept?.additional_document && (
        <AdditionalDocument
          additional_document={detailNoteConcept?.additional_document}
          onDownloadFileHandler={(file) => onDownloadFileHandler(file)}
        />
      )}

      {detailNoteConcept?.status.toLowerCase() === "ditolak" && (
        <CardForm label="Alasan Penolakan">
          <span>{detailNoteConcept?.reason_rejected}</span>
        </CardForm>
      )}

      <div className="flex justify-between sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
        <Button
          label={
            <div className="flex items-center gap-2">
              <BiArrowBack />
              <span>Kembali</span>
            </div>
          }
          size="md"
          className="bg-white"
          onClick={() => navigate("/proposal/konsep-notes")}
        />
      </div>
    </div>
  );
};

const GeneralInformation = ({
  project_name,
  status,
  proposal_title,
  proposal_description,
  main_activity,
  goals_activity,
  outcome,
  output,
}) => {
  return (
    <CardForm label="Informasi Umum Proposal Kegiatan">
      <div className="flex flex-col gap-5">
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Proyek Yang Diajukan</p>
            <p>{project_name ?? "-"}</p>
          </div>
          <div className="flex flex-1 flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Status</p>
            {status ?? "-"}
          </div>
        </div>

        <div className="flex flex-col gap-2 text-gray-800">
          <p className="text-sm font-semibold">Judul Proposal</p>
          <p>{proposal_title ?? "-"}</p>
        </div>

        <div className="flex flex-col gap-2 text-gray-800">
          <p className="text-sm font-semibold">Deskripsi Proposal</p>
          <p>{proposal_description ?? "-"}</p>
        </div>

        <div className="flex flex-col gap-2 text-gray-800">
          <p className="text-sm font-semibold">Aktivitas Utama</p>
          <ul className="list-disc ml-4">
            {main_activity?.map((value) => (
              <li>{value}</li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-2 text-gray-800">
          <p className="text-sm font-semibold">Tujuan Kegiatan</p>
          <ul className="list-disc ml-4">
            {goals_activity?.map((value) => (
              <li>{value}</li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-2 text-gray-800">
          <p className="text-sm font-semibold">Outcome</p>
          <ul className="list-disc ml-4">
            {!_.isEmpty(outcome) && outcome?.map((value) => {
              if (value?.value) {
                return <li>{value?.value}</li>;
              }
            })}
          </ul>
        </div>

        <div className="flex flex-col gap-2 text-gray-800">
          <p className="text-sm font-semibold">Output</p>
          <ul className="list-disc ml-4">
            {!_.isEmpty(output) && output?.map((value) => {
              if (value?.value) {
                return <li>{value?.value}</li>;
              }
            })}
          </ul>
        </div>
      </div>
    </CardForm>
  );
};

const ActivityLocation = ({
  locations,
  is_conversion_area,
  location_conversion_area,
  already_have_permission,
}) => {
  const methods = useForm({
    defaultValues: {
      question: false,
    },
  });

  return (
    <FormProvider {...methods}>
      <CardForm label="Informasi Lokasi Kegiatan">
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-2 gap-6">
            {locations?.map((location, index) => (
              <>
                <div className="flex flex-col gap-2 text-gray-800">
                  <p className="text-sm font-semibold">
                    Lokasi Kegiatan - {index + 1}{" "}
                  </p>
                  <p>
                    {location
                      ? `${typeof location?.province == "object"
                        ? location?.province?.label ?
                          `${location?.province?.label}`
                          : ``
                        : location?.province
                      }${typeof location?.cities == "object"
                        ? location?.cities?.label ?
                          ` - ${location?.cities?.label}`
                          : ``
                        : location?.cities ?
                          ` - ${location.cities}`
                          : ``
                      }${typeof location?.district == "object"
                        ? location?.district?.label ?
                          ` - ${location?.district?.label}`
                          : ``
                        : location?.district ?
                          ` - ${location?.district}`
                          : ``
                      }${typeof location?.village == "object"
                        ? location?.village?.label ?
                          ` - ${location?.village?.label}`
                          : ``
                        : location?.village ?
                          ` - ${location?.village}`
                          : ``
                      }`
                      : `-`}
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-gray-800">
                  <p className="text-sm font-semibold">
                    Lokasi Dalam Maps - {index + 1}{" "}
                  </p>
                  {location?.latitude && location?.longitude ? (
                    <p>{`https://www.google.com/maps/place/${location?.latitude},${location?.longitude}`}</p>
                  ) : '-'}
                </div>
              </>
            ))}

            <div className="flex flex-col gap-2 text-gray-800">
              <p className="text-sm font-semibold">
                Lokasi Kegiatan Benda Diwilayah Konversi
              </p>
              <p>{is_conversion_area ? "Ya" : "Tidak"}</p>
            </div>
            <div className="flex flex-col gap-2 text-gray-800">
              <p className="text-sm font-semibold">Nama Wilayah Konservasi</p>
              <p>{location_conversion_area ?? "-"}</p>
            </div>
          </div>

          <InputFormRadio
            label={
              "Jika lokasi pelaksanaan program/ kegiatan termasuk ke dalam area/ kawasan konservasi,Apakah area/ kawasan konservasi tersebut tidak dilarang dan sudah memperoleh izin?"
            }
            controllerName={`question`}
            values={[
              {
                label: "ya",
                value: already_have_permission ? true : false,
              },
              {
                label: "tidak",
                value: already_have_permission ? false : true,
              },
            ]}
            disabled
          />
        </div>
      </CardForm>
    </FormProvider>
  );
};

const BeneficiariesInformation = ({
  total_beneficiaries,
  is_involve_indigenous_comunities,
  comunities_name,
  is_involve_beneficiaries_from_woman,
  file_beneficiaries_document = [],
  onDownloadFileHandler,
}) => {
  return (
    <CardForm label="Informasi Beneficiaries">
      <div className="flex flex-col gap-6">
        <div className="flex gap-5">
          <div className="flex flex-1 flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">
              Kegiatan Melibatkan Masyarakat Adat
            </p>
            <p>{is_involve_indigenous_comunities ? "Ya" : "Tidak"}</p>
          </div>
          <div className="flex flex-1 flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">
              Nama Masyarakat Adat yang Dilibatkan
            </p>
            <p>{comunities_name}</p>
          </div>
        </div>

        <div className="flex justify-between gap-5">
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">
              Kegiatan Melibatkan Beneficiaries dari Perempuan dan Kelompok
              Rentan
            </p>
            <p>{is_involve_beneficiaries_from_woman ? "Ya" : "Tidak"}</p>
          </div>
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Jumlah Perempuan</p>
            <p>{total_beneficiaries?.female}</p>
          </div>
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Jumlah Laki-laki</p>
            <p>{total_beneficiaries?.male}</p>
          </div>
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Total Beneficiaries</p>
            <p>{total_beneficiaries?.total}</p>
          </div>
        </div>

        {file_beneficiaries_document.map((file, key) => (
          <FileInfoField
            key={'file-beneficiaries-' + key}
            title={file?.fileName}
            className={"w-1/3 text-sm"}
            desc={`${(file?.fileSize / 1000).toFixed(
              2
            )} KB`}
            onDownloadClick={() =>
              onDownloadFileHandler(file)
            }
            showIcon
            showDownload
          />
        ))}
      </div>
    </CardForm>
  );
};

const EnvironmentalAndSafeguardInformation = ({ safeGuards }) => {
  return (
    <CardForm label="Informasi Environmental & Social Safeguard">
      <div className="flex flex-col gap-2 text-gray-800">
        <p className="text-sm font-semibold">
          E & S Safeguard yang Akan Diterapkan
        </p>
        <ul className="list-disc ml-4">
          {safeGuards?.map((el, i) => (
            <li key={"safe-guard-" + i}>{el?.value ?? el}</li>
          ))}
        </ul>
      </div>
    </CardForm>
  );
};

const BudgetPlan = ({
  fund_application_value,
  rab_documents = [],
  onDownloadFileHandler,
}) => {
  return (
    <CardForm label="Rencana Anggaran Biaya">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2 text-gray-800">
          <p className="text-sm font-semibold">Nilai Pengajuan Dana (Rp)</p>
          <p>{fund_application_value}</p>
        </div>

        {rab_documents.map((file, key) => (
          <FileInfoField
            key={'rab-document-' + key}
            title={file?.fileName}
            className={"w-1/3 text-sm"}
            desc={`${(file?.fileSize / 1000).toFixed(2)} KB`}
            onDownloadClick={() => onDownloadFileHandler(file)}
            showIcon
            showDownload
          />
        ))}
      </div>
    </CardForm>
  );
};

const ApplicantInformation = ({ applicant_information }) => {
  return (
    <CardForm label="Informasi Pengusul">
      {applicant_information?.map((el, i) => (
        <div className="grid grid-cols-4 gap-6">
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Nama</p>
            <p>{el?.name}</p>
          </div>
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">NIDN/NIP</p>
            <p>{el?.NIDN_NIP}</p>
          </div>
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Deskripsi</p>
            <p>{el?.description}</p>
          </div>
          <div className="flex flex-col gap-2 text-gray-800">
            <p className="text-sm font-semibold">Jabatan</p>
            <p>{el?.position}</p>
          </div>
        </div>
      ))}
    </CardForm>
  );
};

const AdditionalDocument = ({ additional_document, onDownloadFileHandler }) => {
  return (
    <CardForm label="Dokumen Pendukung">
      <div className="flex flex-col gap-6">
        {additional_document.map((file, key) => (
          <FileInfoField
            key={'additional-document-' + key}
            title={file?.fileName}
            className={"w-1/3 text-sm"}
            desc={`${(file?.fileSize / 1000).toFixed(2)} KB`}
            onDownloadClick={() =>
              onDownloadFileHandler(file)
            }
            showIcon
            showDownload
          />
        ))}
      </div>
    </CardForm>
  );
};

export default KonsepNotesDetail;
