import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { RiAddLine } from 'react-icons/ri';
import {
  Button,
  ConfirmationModal,
  InputForm,
  InputFormUploadNew as InputFormUpload,
} from 'components';
import AtomSelect from 'components/atoms/Select';
import { SuccessToast } from 'components/atoms/Toast';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import CardForm from 'components/molecules/CardForm';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { BiArrowBack } from 'react-icons/bi';
import { useLocation, useNavigate, useParams } from 'react-router';
import { config_daprog } from 'app/config';
import { fileUpload } from 'services/danaProgram/fileService';
import { useSelector } from 'react-redux';
import axiosInstance from 'app/interceptors';
import { REPORT_COMMITMENT_FORM_BREADCRUMBS } from 'contants';
import AtomDatePicker from 'components/atoms/Datepicker';
import {
  getCommitmentReportDetail,
  getListCallforProposal,
  postSubmitCommitmentReport,
} from 'services/danaProgram/callForProposalService';
import { REPORT_PERIOD_OPTIONS } from 'contants/Safeguards';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  cfp: yup.object().required('Judul Proposal wajib diisi'),
  periode: yup.object().required('Periode wajib diisi'),
  tahun: yup.string().required('Tahun wajib diisi'),
  commitment_implementations: yup.array().of(
    yup.object().shape({
      commitment: yup.object().required('Komitmen wajib diisi'),
      activities: yup.array().of(
        yup.object().shape({
          activity: yup.object().required('Kegiatan wajib diisi'),
          implementation_desc: yup.string().required('Deskripsi wajib diisi'),
          doc: yup
            .object()
            .required('Dokumen wajib diisi')
            .typeError('Dokumen wajib diisi'),
        })
      ),
    })
  ),
});

const CommitmentArray = forwardRef(
  ({ onNext, pIndex, commitments, disabled, mutation }, ref) => {
    const { control, watch, setValue } = useFormContext();
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'commitment_implementations',
    });

    const onSubmit = (data) => {
      const payload = {
        id: data?.id,
        cfp_id: data?.cfp_id,
        cfp_title: data?.cfp_title,
        periode: data?.periode?.value,
        tahun: data?.tahun,
        status: 'Menunggu Verifikasi',
        commitment_implementations: data?.commitment_implementations?.map(
          ({ commitment, activities }) => ({
            isp_id: commitment?.isp_id,
            commitment_id: commitment?._id,
            commitment_name: commitment?.desc,
            activities: activities?.map((activity) => {
              return {
                activity_id: activity?.activity?._id,
                activity_name: activity?.activity?.desc,
                desc: activity?.implementation_desc,
                implementation_date: moment(
                  activity?.implementation_date
                ).format('YYYY-MM-DD'),
                doc_type: activity?.doc_type,
                doc: activity?.doc,
              };
            }),
          })
        ),
      };

      mutation.mutate(payload);
    };

    useImperativeHandle(ref, () => {
      return {
        onSubmit,
      };
    });

    function onChangeCommitment(value, index) {
      setValue(`commitment_implementations.${index}.activities`, [
        {
          activity: null,
          implementation_date: null,
          implementation_desc: null,
          doc_type: null,
          docs: [],
        },
      ]);
    }

    return (
      <div>
        {fields.map((commitment, index) => (
          <CardForm
            key={commitment.id}
            label={`Laporan Implementasi Komitmen ${index + 1}`}
            childerForm
            sideLabel=""
            buttonCollapse
          >
            <div>
              <div className="text-[#1D2939] font-semibold text-sm flex flex-row justify-between items-center">
                Komitmen
                {fields.length > 0 && index > 0 && (
                  <Button
                    onClick={() => {
                      remove(index);
                    }}
                    className="rounded-lg border border-gray-300 shadow px-2 bg-error-600 hover:!text-black"
                    label={
                      <div className="flex gap-2 items-center text-white text-xs">
                        Hapus Komitmen
                      </div>
                    }
                  />
                )}
              </div>

              <AtomSelect
                controllerName={`commitment_implementations.${index}.commitment`}
                className={`mt-2 w-full rounded-md`}
                options={commitments}
                label={''}
                placeholder={'Pilih Komitmen'}
                required={true}
                customOnChange={(value) => onChangeCommitment(value, index)}
                disable={disabled}
              />
            </div>

            <hr />

            <ActivityArray
              ispIndex={pIndex}
              commitmentIndex={index}
              control={control}
              disabled={disabled}
              activities={
                watch(`commitment_implementations.${index}.commitment`)
                  ?.activities
              }
            />
          </CardForm>
        ))}

        {!disabled && (
          <div className="text-center">
            <Button
              onClick={() => {
                append({
                  commitment: null,
                  activities: [
                    {
                      activity: null,
                      implementation_date: null,
                      doc_type: null,
                      periode: null,
                      pic: null,
                    },
                  ],
                });
              }}
              className="rounded-lg border border-green-500 bg-white shadow py-2.5 px-4"
              label={
                <div className="flex gap-2 items-center text-gray-700 text-sm">
                  <RiAddLine size={20} />
                  Tambah komitmen
                </div>
              }
            />
          </div>
        )}
      </div>
    );
  }
);

const ActivityArray = ({ commitmentIndex, control, activities, disabled }) => {
  const { setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `commitment_implementations.${commitmentIndex}.activities`,
  });

  const onUploadFile = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response?.data?.data?.url,
        id: response?.data?.data?.id,
        type: response?.data?.data?.mimeType,
        name: response?.data?.data?.name,
        size: response?.data?.data?.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  return (
    <>
      {fields.map((activity, index) => (
        <div
          key={activity.id}
          className="flex flex-col gap-2 border rounded-lg p-4"
        >
          <div>
            <div className="text-[#1D2939] font-semibold text-sm flex flex-row justify-between items-center">
              Kegiatan
              {fields.length > 0 && index > 0 && (
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                  className="rounded-lg border border-gray-300 shadow px-2 bg-error-600 hover:!text-black"
                  label={
                    <div className="flex gap-2 items-center text-white text-xs">
                      Hapus Kegiatan
                    </div>
                  }
                />
              )}
            </div>

            <AtomSelect
              controllerName={`commitment_implementations.${commitmentIndex}.activities.${index}.activity`}
              className={`mt-2 w-full rounded-md`}
              options={activities?.map((el) => ({
                ...el,
                value: el?._id,
                label: el?.desc,
              }))}
              label={''}
              placeholder={'Pilih Kegiatan'}
              required={true}
              disable={disabled}
              customOnChange={(val) => {
                setValue(
                  `commitment_implementations.${commitmentIndex}.activities.${index}.implementation_date`,
                  new Date(val?.implementation_date)
                );
                setValue(
                  `commitment_implementations.${commitmentIndex}.activities.${index}.doc_type`,
                  val?.doc_type
                );
              }}
            />
          </div>

          <div className="flex-1 flex gap-4">
            <AtomDatePicker
              controllerName={`commitment_implementations.${commitmentIndex}.activities.${index}.implementation_date`}
              label="Tanggal Pelaksanaan"
              disable
            />

            <InputForm
              label="Jenis Dokumen Laporan"
              controllerName={`commitment_implementations.${commitmentIndex}.activities.${index}.doc_type`}
              className="mt-2 w-full rounded-md"
              disabled
            />
          </div>

          <div>
            <InputForm
              label={'Deskripsi Pelaksanaan Komitmen'}
              controllerName={`commitment_implementations.${commitmentIndex}.activities.${index}.implementation_desc`}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              rows={5}
              required
              textArea
              disabled={disabled}
            />
          </div>

          <div className="space-y-4">
            <InputFormUpload
              controllerName={`commitment_implementations.${commitmentIndex}.activities.${index}.doc`}
              required
              label="Upload Dokumen Hasil Pelaksanaan Komitmen"
              uploadFile={onUploadFile}
              maxFiles={10}
              disabled={disabled}
            />
          </div>
        </div>
      ))}

      {!disabled && (
        <div className="text-right">
          <Button
            onClick={() => {
              append({
                activity: null,
                implementation_date: null,
                doc_type: null,
                periode: null,
                pic: null,
              });
            }}
            className="rounded-lg border border-green-500 bg-white shadow py-2.5 px-4"
            label={
              <div className="flex gap-2 items-center text-gray-700 text-sm">
                <RiAddLine size={20} />
                Tambah Kegiatan
              </div>
            }
          />
          <hr className="mt-4" />
        </div>
      )}
    </>
  );
};

const CommitmentReportForm = () => {
  const categoryRef = useRef(null);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cfp_id: null,
      cfp_title: null,
      periode: null,
      tahun: null,
      commitment_implementations: [
        {
          commitment_name: null,
          activities: [
            {
              activity: null,
              implementation_date: null,
              implementation_desc: null,
              doc_type: null,
              docs: [],
            },
          ],
        },
      ],
    },
  });

  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [optionAWP, setOptionAWP] = useState([]);
  const [limit, setLimit] = useState(1000);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [commitments, setCommitments] = useState([]);
  const params = useParams();
  const location = useLocation();

  const { data: detail } = useQuery({
    queryKey: ['commitment-details', params?.id],
    queryFn: () => getCommitmentReportDetail(params?.id),
    enabled: !!params?.id,
    select: (data) => data?.data?.data,
  });

  const isReadOnly = !!params?.id && !location.pathname.includes('edit');

  useEffect(() => {
    if (detail && optionAWP.length > 0) {
      const selectedCfp = optionAWP.find((el) => el?.value === detail?.cfp_id);

      onChangeProject(selectedCfp);

      methods.reset({
        id: detail?._id,
        cfp_id: detail?.cfp_id,
        cfp_title: detail?.cfp_title,
        tahun: detail?.tahun,
        cfp: {
          value: detail?.cfp_id,
          label: detail?.cfp_title,
        },
        periode: {
          value: detail?.periode,
          label: detail?.periode,
        },
        commitment_implementations: detail?.commitment_implementations.map(
          (el) => ({
            commitment: {
              _id: el?.commitment_id,
              desc: el?.commitment_name,
              isp_id: el?.isp_id,
              value: el?.commitment_id,
              label: el?.commitment_name,
              activities:
                selectedCfp?.extraData?.safeguard?.list_commitment?.find(
                  (commitment) => commitment?._id === el?.commitment_id
                )?.activities,
            },
            activities: el?.activities.map((act) => {
              return {
                activity: {
                  _id: act?.activity_id,
                  desc: act?.activity_name,
                  value: act?.activity_id,
                  label: act?.activity_name,
                },
                implementation_desc: act?.desc,
                implementation_date: moment(act?.implementation_date, [
                  'YYYY-MM-DD',
                  'DD/MM/YYYY',
                ]).isValid()
                  ? new Date(
                      moment(act?.implementation_date, [
                        'YYYY-MM-DD',
                        'DD/MM/YYYY',
                      ]).format('YYYY-MM-DD')
                    )
                  : null,
                doc_type: act?.doc_type,
                doc: act?.doc,
              };
            }),
          })
        ),
      });
    }
  }, [detail, optionAWP]);

  const fetchProjectProposal = async () => {
    const response = await getListCallforProposal({
      page,
      limit,
      // final_commitment: true,
    });
    const data = await response?.data?.data?.data;

    const listAWP = data.map((el, i) => ({
      value: el?._id,
      label: el?.proposal_title,
      investment_id: el?.investment_id,
      extraData: el,
    }));

    setLastPage(response?.data?.data?.lastPage);

    setOptionAWP(listAWP);
  };

  useEffect(() => {
    fetchProjectProposal();
  }, [page]);

  const submitForm = () => {
    setConfirmModal(true);
  };

  const reachBottom = () => {
    if (page < lastPage) {
      setPage(page + 1);
    }
  };

  function onChangeProject(value) {
    const list = value?.extraData?.safeguard?.list_commitment?.map((el) => ({
      ...el,
      value: el?._id,
      label: el?.desc,
    }));

    setCommitments(list);

    methods.setValue('cfp_id', value?.value);
    methods.setValue('cfp_title', value?.label);
    methods.setValue('cfp', value);

    methods.reset({
      ...methods.getValues(),
      commitment_implementations: [
        {
          commitment: null,
          activities: [
            {
              activity: null,
              implementation_date: null,
              implementation_desc: null,
              doc_type: null,
              docs: [],
            },
          ],
        },
      ],
    });
  }

  const { enqueueSnackbar } = useSnackbar();
  const commitmentMutation = useMutation({
    mutationKey: ['commitmentMutation'],
    mutationFn: postSubmitCommitmentReport,
    onSuccess: (res) => {
      navigate('/report/commitment');
      enqueueSnackbar(res?.data?.message ?? 'Success', {
        variant: 'success',
      });
    },
  });

  return (
    <>
      {/* Modal */}
      {success && (
        <SuccessToast
          onClose={() => setSuccess(!success)}
          message={'Data Berhasil Dikirim!'}
          message_description="Data yang anda masukkan sudah berhasil dikirim"
        />
      )}

      <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <BreadCrumbs
            routes={[
              ...REPORT_COMMITMENT_FORM_BREADCRUMBS,
              {
                label: isReadOnly
                  ? 'Detail'
                  : params?.id
                  ? 'Ubah'
                  : 'Buat Baru',
              },
            ]}
          />
        </div>

        <FormProvider {...methods}>
          <CardForm label="Laporan Implementasi Komitmen Perlindungan Lingkungan & Sosial">
            <div className="flex flex-col gap-4">
              <AtomSelect
                controllerName="cfp"
                className={`mt-2 w-full rounded-md`}
                options={optionAWP}
                label={'Judul Proposal'}
                placeholder={'Pilih Judul Proposal'}
                required={true}
                onMenuScrollToBottom={reachBottom}
                customOnChange={onChangeProject}
                disable={isReadOnly}
              />

              <div className="flex flex-row gap-4">
                <AtomSelect
                  controllerName="periode"
                  className={`mt-2 w-full rounded-md`}
                  options={REPORT_PERIOD_OPTIONS}
                  label={'Periode yang Dilaporkan'}
                  placeholder={'Pilih Periode yang Dilaporkan'}
                  required={true}
                  disable={isReadOnly}
                  // onMenuScrollToBottom={reachBottom}
                />

                <InputForm
                  controllerName="tahun"
                  className={`mt-2 w-full rounded-md`}
                  label={'Tahun'}
                  placeholder={'Tahun'}
                  required={true}
                  disabled={isReadOnly}
                  forceNumber
                  // onMenuScrollToBottom={reachBottom}
                />
              </div>

              <p className="font-bold text-lg text-green-500">
                Laporan Implementasi Komitmen
              </p>

              <CommitmentArray
                ref={categoryRef}
                onNext={submitForm}
                commitments={commitments}
                disabled={isReadOnly}
                mutation={commitmentMutation}
              />
            </div>
          </CardForm>
        </FormProvider>

        <div className="flex justify-between sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
          <Button
            label={
              <div className="flex items-center gap-2">
                <BiArrowBack />
                <span>Kembali</span>
              </div>
            }
            size="md"
            className="bg-white"
            onClick={() => navigate('/proposal/konsep-notes')}
          />
          <Button
            label="Submit"
            size="md"
            disabled={commitmentMutation.isLoading}
            onClick={methods.handleSubmit(categoryRef?.current?.onSubmit)}
            className="bg-primary-600 hover:bg-primary-600/90 text-white"
          />
        </div>
      </div>
    </>
  );
};

export default CommitmentReportForm;
