import React, { Fragment, memo, useEffect, useState } from 'react';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import CardForm from 'components/molecules/CardForm';
import { BottomFormAction, Button, InputForm } from 'components';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import AccordionButton from 'components/atoms/AccordionButton';
import { BiArrowBack } from 'react-icons/bi';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getDetailOutputProgressReportByProposal, getDetailProgressReportByProposal, updateDetailProgressReportByProposal } from 'services/danaProgram/callForProposalService';
import { useNavigate, useParams } from 'react-router';
import AtomSelect from 'components/atoms/Select';
import _ from 'lodash';
import { fileUpload } from 'services/danaProgram/fileService';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import FileDetail from 'components/molecules/FileDetail';

// const validationSchema = {
//   proposal_id: Yup.string().required(),
//   project_name: Yup.object().shape({
//     value: Yup.string().required(),
//     label: Yup.string().required()
//   }),
//   output: Yup.array().required(),
//   document: Yup.array().of(
//     Yup.mixed().nullable()
//   ).nullable()
// };

const DetailRencanaKemajuan = () => {
  const [fileDoc, setFileDoc] = useState([]);
  const [accordionOutput, setAccordionOutput] = useState();
  const { id } = useParams();
  const items = [
    {
      label: "Funding Proposal",
      path: '/proposal/call-for-proposal'
    },
    {
      label: "laporan kemajuan",
      path: `/proposal/call-for-proposal/${id}`
    },
    { label: "Update" }
  ];

  const defaultValues = {
    proposal_id: "",
    project_name: null,
    output: [],
    document: []
  };

  const navigate = useNavigate();
  const methods = useForm({
    defaultValues,
  });
  const { control } = methods;

  const { data: dataDetailProgressReport } = useQuery({
    queryKey: ["application-get-detail-progress-report"],
    queryFn: async () => {
      const res = await getDetailProgressReportByProposal({ proposal_id: id });
      return res?.data?.data || {}
    }
  });

  const { data: dataOutputProgressReport } = useQuery({
    queryKey: ["application-get-detail-output-progress-report"],
    queryFn: async () => {
      const res = await getDetailOutputProgressReportByProposal({ proposal_id: id });
      return res?.data?.data || {}
    }
  });

  useEffect(() => {
    if(
      !_.isEmpty(dataDetailProgressReport) && 
      !_.isEmpty(dataOutputProgressReport)
    ) {
      methods.reset({
        id: dataDetailProgressReport?._id,
        proposal_id: id,
        project_name: { value: dataOutputProgressReport?.project_name, label: dataOutputProgressReport?.project_name },
        output: dataDetailProgressReport?.output,
        document: dataDetailProgressReport?.document
      });

      setAccordionOutput(
        Array.from({ length: dataDetailProgressReport?.output?.length }, () => false)
      );
      setFileDoc(dataDetailProgressReport?.document);
    }
  }, [dataDetailProgressReport, dataOutputProgressReport]);

  const NestedOutput = ({ data, control, outputKey, accordionOutput, setAccordionOutput }) => {
    const { fields: indicatorFields } = useFieldArray({
      control,
      name: `output.${outputKey}.indicator_list`
    })

    return (
      <Fragment>
        <AccordionButton
          label={`Output ${data?.name}`}
          isOpen={accordionOutput[outputKey]}
          onClick={() => setAccordionOutput(outputKey)}
        />
        <div
          className={`${
            accordionOutput[outputKey]
              ? "opacity-1 h-full visible"
              : "opacity-0 h-0 invisible max-h-0"
          }`}
        >
          <div className="space-y-4">
            {indicatorFields.map((indicator, key) => (
              <div key={'indicator-' + key} className="flex w-full space-x-4">
                <div className="w-[70%]">
                  <InputForm
                    label={`Indikator`}
                    controllerName={`output.${outputKey}.indicator_list.${key}.name`}
                    className="w-full"
                    disabled={true}
                  />
                </div>
                <div className="w-[30%]">
                  <InputForm
                    controllerName={`output.${outputKey}.indicator_list.${key}.contribution`}
                    label="Update Kontribusi Capaian"
                    className="w-full"
                  />
                </div>
              </div>
              ))}
          </div>
        </div>
      </Fragment>
    );
  };

  const { fields: outputFields } = useFieldArray({
    control,
    name: 'output'
  });

  const setAccordion = (key) => {
    setAccordionOutput(prev => {
      const temp = [...prev];
      temp[key] = !temp[key];
      return temp;
    });
  }

  const postFile = useMutation({
    mutationKey: ["post-file"],
    mutationFn: (data) => {
      const promise = data.document.map(async doc => {
        const formData = new FormData();
        formData.append("file", doc);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      })

      return Promise.all(promise);
    },
    onSuccess: (res) => {
      let payload = {
        ...methods.getValues(),
        document: res
      };
      delete payload["project_name"];

      updateProgressReport.mutate(payload);
    }
  })

  const updateProgressReport = useMutation({
    mutationKey: ["update-progress-report"],
    mutationFn: async (data) => {
      const res = await updateDetailProgressReportByProposal(data);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar({
        message: "Data berhasil diupdate",
        variant: "success",
      });
      navigate(-1);
    }
  })

  const onSubmit = (data) => {
    postFile.mutate(data);
  }

  return (
    <>
      <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <BreadCrumbs routes={items} />
          <hr />

          <FormProvider {...methods}>
            <CardForm label="Update Laporan Kemajuan">
              <AtomSelect
                controllerName={`project_name`}
                label={"Nama Project"}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                options={[]}
                disable={true}
              />
              <span className="font-bold">Capaian Output</span>
              {outputFields?.map((data, i) => (
                <NestedOutput
                  key={"output-list-" + i}
                  outputKey={i}
                  data={data}
                  control={control} 
                  accordionOutput={accordionOutput}
                  setAccordionOutput={setAccordion}
                />
              ))}
            </CardForm>
            <CardForm label="Bukti Pendukung">
              {fileDoc.length > 0 ? (
                fileDoc.map((item, key) => (
                  <FileDetail
                    key={'file-doc-' + key}
                    file={item}
                    onRemove={() => {
                      const filtered = fileDoc.filter(
                        (file) => file.fileId !== item.fileId
                      );

                      setFileDoc(filtered);
                    }}
                  />
                ))
              ) : (
                <InputFormDropzone
                  name={`document`}
                  accept={{
                    "image/png": [],
                    "image/jpeg": [],
                    "application/pdf": []
                  }}
                  maxFiles={10}
                  maxSize={10}
                  multiple={true}
                  informationText="PNG, JPG, PDF"
                />
              )}
            </CardForm>
            <div className="flex justify-end space-x-4 sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
              <Button
                onClick={() => navigate(-1)}
                label={
                  <div className="flex items-center gap-2">
                    <BiArrowBack />
                    <span>Kembali</span>
                  </div>
                }
                size="md"
                className="bg-white"
              />
              <Button
                onClick={() => methods.handleSubmit(onSubmit)()}
                label="Submit"
                size="md"
                className="bg-primary-600 hover:bg-primary-600/90 text-white"
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default DetailRencanaKemajuan;