import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  BottomFormAction,
  ConfirmationModal,
  InputFormRadio,
  Spinner,
} from 'components';
import { AlertWarning } from 'components/molecules/Alert';
import QuestionBadge from 'components/molecules/Badge/QuestionBadge';
import CardForm from 'components/molecules/CardForm';
import FileDetail from 'components/molecules/FileDetail';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  getDetailSafeguards,
  postSafeguardsStep2,
} from 'services/danaProgram/callForProposalService';
import { fileUpload } from 'services/danaProgram/fileService';
import { getNegativeList } from 'services/masterData/negativeList';
import * as yup from 'yup';

const schema = yup.object({
  negative_list_status: yup
    .boolean()
    .required('Wajib diisi')
    .typeError('Wajib diisi'),
});

const NegativeFilter = ({ onNext, onBack, type }) => {
  const navigate = useNavigate();
  const [initDocs, setInitDocs] = useState([]);
  const safeguard = useSelector((state) => {
    return state?.callForProposalData.data.category.find(
      (item) => item.id === 'safeguards'
    );
  });
  const { id, editable } = useSelector(
    (state) => state?.callForProposalData?.data
  );

  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    queryKey: ['get-detail-safeguard-2'],
    queryFn: async () => {
      const res = await getDetailSafeguards({ proposal_id: id });
      return res.data.data;
    },
  });

  useEffect(() => {
    if (dataDetail) {
      setInitDocs(dataDetail?.filter_negative_list?.supportive_doc);
      methods.reset({
        id: dataDetail?._id,
        negative_list_status:
          dataDetail?.filter_negative_list?.negative_list_status,
        document: [],
      });
    }
  }, [dataDetail]);

  const { data, isFetching } = useQuery({
    queryKey: ['get-data-negative-list'],
    queryFn: async () => {
      const res = await getNegativeList();
      return res.data.data;
    },
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: '',
      negative_list_status: null,
      document: [],
    },
  });

  const { getValues } = methods;

  const onSubmit = () => {
    postFile.mutate();
  };

  const postFile = useMutation({
    mutationKey: ['post-file'],
    mutationFn: async () => {
      const data = getValues('document')?.map(async (item) => {
        const formData = new FormData();
        formData.append('file', item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });
      return Promise.all(data);
    },
    onSuccess: (res) => {
      let payload = {
        ...getValues(),
        document: res?.[0] ? [res[0], ...(initDocs || [])] : [...initDocs],
      };
      submitStep2.mutate(payload);
    },
  });

  const submitStep2 = useMutation({
    mutationKey: ['post-step-2'],
    mutationFn: async (payload) => {
      const res = await postSafeguardsStep2(payload);
      return res;
    },
    onSuccess: (res) => {
      onNext(type);
    },
  });

  return (
    <div className="gap-4 flex flex-col">
      {isFetching || isFetchingDetail ? (
        <div className="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <AlertWarning
            children={
              <div>
                <p>Lengkapi Data</p>
                <small className="font-normal text-slate-700">
                  Silahkan isi semua data dibawah ini untuk bisa melanjutkan
                  pengisian ke tahap selanjutnya
                </small>
              </div>
            }
          />
          <FormProvider {...methods}>
            <CardForm label="Informasi Proyek dan atau kegiatan yang berhubungan dengan perlindungan lingkungan dan sosial">
              {data?.map((item, index) => (
                <div
                  key={index}
                  className="p-2 w-full bg-green-100 border-green-400 border rounded-md font-semibold"
                >
                  {`${index + 1}. ${item?.nama}`}
                </div>
              ))}
              <hr />
              <QuestionBadge
                text="Berdasarkan hasil penyaringan terhadap daftar negatif di atas, diperoleh bahwa program dan/atau
kegiatan yang Anda usulkan :"
              />
              <InputFormRadio
                disabled={!editable}
                controllerName="negative_list_status"
                values={[
                  {
                    label: 'Termasuk dalam daftar negatif',
                    value: true,
                  },
                  {
                    label: 'Tidak Termasuk dalam daftar negatif',
                    value: false,
                  },
                ]}
              />
              <div>
                <label>Dokumen Pendukung</label>
                {initDocs?.length > 0 ? (
                  <>
                    {initDocs?.map((item, index) => (
                      <FileDetail
                        file={item}
                        key={index}
                        hideDelete={!editable}
                        onRemove={() => {
                          const filtered = initDocs.filter(
                            (file) => file.fileId !== item.fileId
                          );
                          setInitDocs(filtered);
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <InputFormDropzone
                    name="document"
                    accept={{
                      'image/png': [],
                      'image/jpeg': [],
                      'application/pdf': [],
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        [],
                    }}
                    disabled={!editable}
                    maxFiles={1}
                    maxSize={10}
                    multiple={false}
                    informationText="PNG, JPG, PDF, Docx"
                  />
                )}
              </div>
            </CardForm>
            <BottomFormAction
              lastStep={false}
              backButtonAction={() => onBack()}
              disableDrafButton={false}
              draftLabel={'Kembali Ke Proposal'}
              drafButtonAction={() => {
                navigate(`/proposal/call-for-proposal/${id}`);
              }}
              isLoading={postFile.isLoading || submitStep2.isLoading}
              // disableButtonSubmit={isValid ? false : true}
              submitActionButton={() => {
                methods.handleSubmit(onSubmit)();
              }}
            />
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default NegativeFilter;
