import React, { Fragment, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import BreadCrumbs from 'components/molecules/Breadcrumbs';
import Stepper from 'pages/Report/Components/Stepper';

import ActivityCost from './ActivityCost';
import ResultActivity from './ResultActivity';
import { useQuery } from '@tanstack/react-query';
import { getDetailActivityReport } from 'services/danaProgram/callForProposalService';
import { formatCurrency } from 'components/molecules/InputForm';

const links = [
  {
    label: 'Laporan',
    path: '/report'
  },
  {
    label: 'Laporan Kegiatan',
    path: '/report/activity'
  },
  {
    label: 'Buat Baru',
    path: '/report/activity/form'
  },
];

const stepperItems = [
  {
    label: 'Hasil Pelaksanaan Kegiatan',
  },
  {
    label: 'Realisasi Biaya',
  },
];

const ReportActivityForm = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const detailId = queryParams.get("id");
  const isDetail = queryParams.get("detail") === "true";
  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({});
  const [activityResultValues, setActivityResultValues] = useState({});
  const [costRealizationValues, setCostRealizationValues] = useState({});

  const { data, isLoading } = useQuery({
    queryKey: ["get-detail-activity-report"],
    queryFn: async () => {
      const res = await getDetailActivityReport({ id: detailId });

      return res?.data?.data || {}
    },
    enabled: isDetail,
  });


  const onBack = useCallback(
    (data) => {
      if (currentStep > 0) {
        setCostRealizationValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(0);
      }
    },
    [currentStep]
  );

  const onSubmit = useCallback(
    (data) => {
      if (currentStep < 1) {
        setPayload((curr) => ({
          ...curr,
          ...data,
        }));
        setActivityResultValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(1);
      } else {
        if (!isEmpty(payload)) {
          const dataToSend = {
            ...payload,
            ...data,
          };

          console.log('data to send ', dataToSend);
        }
      }
    },
    [currentStep, payload]
  );

  const convertActivityDate = (activityDate) => {
    if (!activityDate) return null;

    const dates = activityDate.split(' - ');
    const formatDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
      return date;
    };

    const formatOneDate = (dateStr) => {
      const date = new Date(dateStr);
      return date;
    };


    if (dates.length === 1) {
      return formatOneDate(dates[0]);
    }

    const formattedStartDate = formatDate(dates[0]);
    const formattedEndDate = formatDate(dates[1]);

    return [formattedStartDate, formattedEndDate];
  };

  const activityResultDefaultValues =
    useMemo(
      () => {
        if (data) {
          return {
            activity_name: data?.activity_name,
            status: data?.status,
            proposal_name: data?.proposal_name,
            activity_goals: data?.activity_goals,
            partners: data?.partners,
            output: data?.output,
            activity_date: convertActivityDate(data?.activity_date),
            partner: data?.partner,
            total_man: data?.total_man,
            total_woman: data?.total_woman,
            activity_result: {
              activity_result: data?.activity_result,
              attachment: data?.document
            },
            start_date: data?.start_date
              ? moment(data?.start_date).toDate()
              : null,
            end_date: data?.end_date
              ? moment(data?.end_date).toDate()
              : null,
          }
        }
        return {
          main_activity: null,
          status: 1,
          activity_goals: [],
          partners: [],
          involvement: {
            male: null,
            female: null,
          },
          // safeguards: [],
          activity_result: {
            activity_result: '',
            attachment: [],
          },
          ...activityResultValues,
          start_date: activityResultValues?.start_date
            ? moment(activityResultValues?.start_date).toDate()
            : null,
          end_date: activityResultValues?.end_date
            ? moment(activityResultValues?.end_date).toDate()
            : null,
        }
      },
      [activityResultValues, data]
    );

  const costRealizationDefaultValues = useMemo(
    () => {
      if (data) {
        return {
          detail_activity: data?.detail_activity?.map((activity) => {
            return {
              name: activity?.name,
              implementation_date: convertActivityDate(activity?.implementation_date),
              realized_value: formatCurrency(activity?.realized_value),
            }
          }) || [],
          document: data?.detail_activity_document || [],
        }
      }
      return {
        detail_activity: [
          {
            name: '',
            implementation_date: '',
            realized: 0,
          },
        ],
        document: [],
        ...costRealizationValues,
      }
    },
    [costRealizationValues, data]
  );

  return (
    <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
      <div className="flex flex-col gap-4">
        <BreadCrumbs routes={links} />
        <hr />
        <div className="w-full mt-6 mb-6 flex justify-center items-center">
          <Stepper currentStep={currentStep} items={stepperItems} />
        </div>
        {
          isLoading && isDetail ? <div>Loading...</div>
            :
            currentStep < 1 ? (
              <ResultActivity
                defaultValues={activityResultDefaultValues}
                onNext={onSubmit}
              />
            ) : (
              <ActivityCost
                onBack={onBack}
                onNext={onSubmit}
                defaultValues={costRealizationDefaultValues}
                payload={payload}
              />
            )}
      </div>
    </div>
  );
};

export default ReportActivityForm;
