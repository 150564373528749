/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { getHomepageStatus } from 'services/danaProgram/homepage';

import PenilaianLemtaraChild from './penilaianLemtaraChild';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

const PenilaianLemtara = () => {
  let userId = useSelector((state) => state.auth.user.UserId);

  const { data } = useQuery({
    queryKey: ['homepageStatus', userId],
    queryFn: async () => getHomepageStatus(userId),
    enabled: !!userId,
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return (
    <div className="w-full space-y-4">
      <PenilaianLemtaraChild data={data?.data?.body} userId={userId} />
    </div>
  );
};

export default PenilaianLemtara;
