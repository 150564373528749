import React, { useMemo, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import AlertBlankData from "components/molecules/Alert";
import { ConfirmationModal, HeaderLabel, StepProgress } from "components";
import SumberdayaManusia from "./SumberdayaManusia";
import PeralatanPerlengkapan from "./PeralatanPerlengkapan";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import {
  updateResourcesDraftData,
  resetResourcesDraftData,
  updateCompletedStatus,
} from "app/actions";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { isEqual } from "lodash";
import { ToastContext } from "components/atoms/Toast";
import {
  getResources,
  resourcesSubmit,
} from "services/danaProgram/resourcesService";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useAuthContext } from "providers/authProvider";

const SumberDaya = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";
  const methods = useForm();
  const { showToastMessage, showToastDescription } = useContext(ToastContext);
  const [activeStep, setActiveStep] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [resourcesProfileDraftData, setResourcesProfileDraftData] = useState();
  const [dataInstrument, setDataInstruments] = useState(null);
  const draftDataOnRedux = useSelector(
    (state) => state?.resourcesDraftData?.data || {}
  );

  const userId = localStorage.getItem(`userId`);

  const mutation = useMutation({
    mutationFn: (params) => {
      return resourcesSubmit(params);
    },
    onSuccess: (data) => {
      if (mutation.variables.status === "SUBMITTED") {
        dispatch(
          updateCompletedStatus({ sumber_daya: true }, () => {
            navigate("/penilaian-lemtara");
            showToastMessage(
              "Data Berhasil Disimpan!",
              "Data yang anda masukan telah berhasil disimpan."
            );
          })
        );
      }
    },
  });

  const fetchMutation = useMutation({
    mutationFn: async () => {
      try {
        const promise = await getResources();
        const dataStatus = promise.data.data;
        setResourcesProfileDraftData(dataStatus);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  });

  const consumeAPI = (data, status) => {
    let params;
    if (status === "DRAFTED") {
      switch (activeStep) {
        case 1:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            step_1: {
              partner_list: {
                total_employee: data?.total_employee,
                total_contract_employee: data?.total_contract_employee,
                total_staff_program: data?.total_staff_program,
                program_personnel: data?.program_personnel?.map((data) => ({
                  identity_number: data?.identity_number,
                  name: data?.name,
                  gender: data?.gender,
                  employee_status: data?.employee_status,
                  dob: data?.dob,
                  education: data?.education,
                  position: data?.position,
                  work_experience: data?.work_experience,
                  skills: data?.skills,
                  url_file_education_certificate:
                    data?.url_file_education_certificate,
                  url_file_resume: data?.url_file_resume?.id,
                })),
                finance_personnel: data?.finance_personnel?.map((data) => ({
                  identity_number: data?.identity_number,
                  name: data?.name,
                  employee_status: data?.employee_status,
                  dob: data?.dob,
                  education: data?.education,
                  position: data?.position,
                  work_experience: data?.work_experience,
                  skills: data?.skills,
                  url_file_education_certificate:
                    data?.url_file_education_certificate,
                  url_file_resume: data?.url_file_resume?.id,
                  gender: data?.gender,
                })),
                safeguard_personnel: data?.safeguard_personnel?.map((data) => ({
                  identity_number: data?.identity_number,
                  name: data?.name,
                  employee_status: data?.employee_status,
                  dob: data?.dob,
                  education: data?.education,
                  position: data?.position,
                  work_experience: data?.work_experience,
                  skills: data?.skills,
                  url_file_education_certificate:
                    data?.url_file_education_certificate,
                  url_file_resume: data?.url_file_resume?.id,
                  gender: data?.gender,
                })),
                other_personnel: data?.other_personnel?.map((data) => ({
                  identity_number: data?.identity_number,
                  name: data?.name,
                  employee_status: data?.employee_status,
                  dob: data?.dob,
                  education: data?.education,
                  position: data?.position,
                  work_experience: data?.work_experience,
                  skills: data?.skills,
                  url_file_education_certificate:
                    data?.url_file_education_certificate,
                  url_file_resume: data?.url_file_resume?.id,
                  gender: data?.gender,
                })),
                work_performance: data?.work_performance,
                punishment: data?.punishment,
              },
              document_support: {
                url_file_structure_institution:
                  data?.document_support?.url_file_structure_institution?.id,
                description_structure_institution:
                  data?.document_support?.description_structure_institution,
                url_file_job_description:
                  data?.document_support?.url_file_job_description?.id,
                description_job_description:
                  data?.document_support?.description_job_description,
              },
            },
          };
          break;
        case 2:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            step_2: {
              equipment: {
                list: data?.instrumentations?.map((dat, index) => ({
                  name: dat?.name ?? "",
                  category: dat?.category ?? "",
                  total: dat?.total ?? "",
                  capacity: dat?.capacity ?? "",
                  mark_and_type: dat?.mark_and_type ?? "",
                  production_year: dat?.production_year ?? "",
                  condition: dat?.condition ?? "",
                  location: dat?.location ?? "",
                  url_file_proof_of_ownership:
                    dat?.url_file_proof_of_ownership?.id ?? "",
                })),
              },
            },
          };
          break;
        default:
          break;
      }
    } else {
      params = {
        user_id: userId,
        status: status,
        current_step: activeStep,
        step_1: {
          partner_list: {
            total_employee: draftDataOnRedux?.human_resources?.total_employee,
            total_contract_employee:
              draftDataOnRedux?.human_resources?.total_contract_employee,
            total_staff_program:
              draftDataOnRedux?.human_resources?.total_staff_program,
            program_personnel:
              draftDataOnRedux?.human_resources?.program_personnel?.map(
                (obj) => ({
                  identity_number: obj.identity_number,
                  name: obj.name,
                  employee_status: obj.employee_status ?? null,
                  dob: obj.dob,
                  education: obj.education ?? null,
                  position: obj.position,
                  work_experience: obj.work_experience,
                  skills: obj.skills,
                  gender: obj?.gender,
                  url_file_education_certificate:
                    obj?.url_file_education_certificate,
                  url_file_resume: obj.url_file_resume?.id,
                })
              ),
            finance_personnel:
              draftDataOnRedux?.human_resources?.finance_personnel?.map(
                (obj) => ({
                  identity_number: obj.identity_number,
                  name: obj.name,
                  employee_status: obj.employee_status ?? null,
                  dob: obj.dob,
                  education: obj.education ?? null,
                  position: obj.position,
                  work_experience: obj.work_experience,
                  skills: obj.skills,
                  gender: obj?.gender,
                  url_file_education_certificate:
                    obj?.url_file_education_certificate,
                  url_file_resume: obj.url_file_resume?.id,
                })
              ),
            safeguard_personnel:
              draftDataOnRedux?.human_resources?.safeguard_personnel?.map(
                (obj) => ({
                  identity_number: obj.identity_number,
                  name: obj.name,
                  employee_status: obj.employee_status ?? null,
                  dob: obj.dob,
                  education: obj.education ?? null,
                  position: obj.position,
                  work_experience: obj.work_experience,
                  skills: obj.skills,
                  gender: obj?.gender,
                  url_file_education_certificate:
                    obj?.url_file_education_certificate,
                  url_file_resume: obj.url_file_resume?.id,
                })
              ),
            other_personnel:
              draftDataOnRedux?.human_resources?.other_personnel?.map(
                (obj) => ({
                  identity_number: obj.identity_number,
                  name: obj.name,
                  employee_status: obj.employee_status ?? null,
                  dob: obj.dob,
                  education: obj.education ?? null,
                  position: obj.position,
                  work_experience: obj.work_experience,
                  skills: obj.skills,
                  gender: obj?.gender,
                  url_file_education_certificate:
                    obj?.url_file_education_certificate,
                  url_file_resume: obj.url_file_resume?.id,
                })
              ),
            work_performance:
              draftDataOnRedux?.human_resources?.work_performance,
            punishment: draftDataOnRedux?.human_resources?.punishment,
          },
          document_support: {
            url_file_structure_institution:
              draftDataOnRedux?.human_resources?.document_support
                ?.url_file_structure_institution?.id,
            description_structure_institution:
              draftDataOnRedux?.human_resources?.document_support
                ?.description_structure_institution,
            url_file_job_description:
              draftDataOnRedux?.human_resources?.document_support
                ?.url_file_job_description?.id,
            description_job_description:
              draftDataOnRedux?.human_resources?.document_support
                ?.description_job_description,
          },
        },
        step_2: {
          equipment: {
            list: data?.instrumentations?.map((dat, index) => ({
              ...dat,
              url_file_proof_of_ownership:
                dat?.url_file_proof_of_ownership?.id ?? "",
            })),
          },
        },
      };
    }
    mutation.mutate(params);
  };

  const dispatchData = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => key.includes("url"))
    );

    if (activeStep === 1) {
      dispatch(
        updateResourcesDraftData({
          human_resources: {
            ...data,
          },
        })
      );
    } else if (activeStep === 2) {
      dispatch(
        updateResourcesDraftData({
          equipment_resources: {
            ...filteredData,
          },
        })
      );
    }
  };

  const onDraftSubmit = (data) => {
    consumeAPI(data, "DRAFTED");
    dispatchData(data);
    setConfirmDraftModal(true);
  };

  const onBack = (data) => {
    if (isReadonly) {
      if (activeStep === 1) {
        navigate(-1);
      } else {
        setActiveStep(activeStep - 1);
      }
      dispatchData(data);
      return
    }
    dispatchData(data);
    consumeAPI(data, "DRAFTED");
    setConfirmBackModal(true);
  };

  const onSubmit = (data) => {
    if (isReadonly) {
      setActiveStep(activeStep + 1);
      return
    }

    if (activeStep !== 2) {
      dispatchData(data);
      consumeAPI(data, "DRAFTED");
      setActiveStep(activeStep + 1);
    } else {
      setConfirmModal(true);
      dispatchData(data);
      setDataInstruments(data);
    }
  };

  const onSubmitConfirmModal = (data) => {
    consumeAPI(dataInstrument, "SUBMITTED");
  };

  const onSubmitConfirmBackModal = () => {
    if (activeStep === 1) {
      navigate(-1);
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
    } else {
      setActiveStep(activeStep - 1);
    }
    setConfirmBackModal(false);
  };

  const onSubmitConfirmDraftModal = () => {
    navigate("/penilaian-lemtara");
    showToastMessage(
      "Data Berhasil Disimpan!",
      "Data yang anda masukan telah berhasil disimpan."
    );
  };

  useEffect(() => {
    fetchMutation.mutate();
  }, [activeStep]);

  const breadCrumbs = [
    {
      label: "Penilaian LEMTARA",
      path: "/penilaian-lemtara",
    },
    {
      label: "Sumber Daya",
      path: "/sumber-daya",
    },
  ];

  const stepProgress = useMemo(() => [
    {
      label: "Sumber Daya Manusia",
      step: 1,
    },
    {
      label: "Peralatan / Perlengkapan",
      step: 2,
    },
  ]);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitConfirmModal}
          onCancel={() => setConfirmBackModal(false)}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmDraftModal}
          onClose={setConfirmDraftModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={() => onSubmitConfirmDraftModal()}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin menyimpan ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda menyimpan sebagai draft anda masih bisa melanjutkan.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmBackModal}
          onClose={setConfirmBackModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={() => onSubmitConfirmBackModal()}
          className="space-y-2 mb-4"
        >
          <div>
            <div className="text-lg font-[600]">
              Apakah anda yakin untuk kembali?
            </div>
            <div className="text-sm font-[400]">
              Setelah anda kembali semua data akan disimpan pada draft.
            </div>
          </div>
        </ConfirmationModal>,
        document.body
      )}
      <div className="w-full space-y-6 bg-white  rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={breadCrumbs} />

        <div className="flex">
          <HeaderLabel
            text="Sumber Daya"
            description="Silahkan daftar menggunakan data yang Anda miliki."
          />
        </div>

        <div className="border-b" />
        <div className="px-10">
          <StepProgress
            stepProgressData={stepProgress}
            activeStep={activeStep}
          />
        </div>

        <div className="w-full pt-4">
          <AlertBlankData
            title={`Lengkapi Data`}
            body={`Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
          />
        </div>

        {activeStep === 1 && (
          <SumberdayaManusia
            onSubmit={onSubmit}
            onBack={onBack}
            // onDraftSubmit={() => dispatch(resetResourcesDraftData())}
            onDraftSubmit={onDraftSubmit}
            draftData={resourcesProfileDraftData?.step_1}
          />
        )}
        {activeStep === 2 && (
          <PeralatanPerlengkapan
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={resourcesProfileDraftData?.step_2?.equipment}
          />
        )}
      </div>
    </>
  );
};

export default SumberDaya;
