import { Button, Spinner } from 'components';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { RiAddLine } from 'react-icons/ri';
import TablePagination from 'components/atoms/Pagination';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getCommitmentReportList } from 'services/danaProgram/callForProposalService';
import { AiOutlineEye } from 'react-icons/ai';
import Select from '../Components/Select';
import SearchInput from '../Components/SearchInput';

const rowPerPageOptions = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
];

const STATUS_ARRAY = [
  'Menunggu Verifikasi',
  'Telah Diverifikasi',
  'Perlu Revisi',
  'Ditolak',
];

const StatusBadge = ({ status }) => {
  let defaultDotClass = 'bg-gray-500';
  let defaultWrapperClass = 'bg-gray-50 text-gray-700';

  switch (status) {
    case 'Telah Diverifikasi':
      defaultDotClass = 'bg-bluelight-500';
      defaultWrapperClass = 'bg-bluelight-50 text-bluelight-700';
      break;
    case 'Menunggu Verifikasi':
      defaultDotClass = 'bg-purple-500';
      defaultWrapperClass = 'bg-purple-50 text-purple-700';
      break;
    case 'Perlu Revisi':
      defaultDotClass = 'bg-pink-500';
      defaultWrapperClass = 'bg-pink-50 text-pink-700';
      break;
    case 'Ditolak':
      defaultDotClass = 'bg-red-500';
      defaultWrapperClass = 'bg-red-50 text-red-700';
      break;

    default:
      defaultDotClass = 'bg-gray-500';
      defaultWrapperClass = 'bg-gray-50 text-gray-700';
      break;
  }
  return (
    <div
      className={`rounded-2xl flex items-center border-transparent p-2 text-xs w-fit ${defaultWrapperClass}`}
    >
      <div
        className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`}
      />
      <div className="flex-1 text-center whitespace-nowrap">{status}</div>
    </div>
  );
};

const ReportActivity = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [dataSource, setDataSource] = useState({});
  const [statusFilter, setStatusFilter] = useState(null);
  const [submitedDate, setSubmitedDate] = useState(null);
  const [rowPerPage, setRowPerPage] = useState({ value: 10, label: '10' });
  const [modal, setModal] = useState({
    title: '',
    description: '',
    type: '',
    open: false,
    data: null,
    cancelLabel: '',
    confirmLabel: '',
  });
  const params = {
    page: page,
    limit: limit,
    name: keyword,
    status: statusFilter?.value,
    submited_date: submitedDate && moment(submitedDate).format('YYYY-MM-DD'),
  };

  const {
    data: list,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['list-report-activity', params],
    queryFn: () => getCommitmentReportList(params),
  });

  useEffect(() => {
    refetch();
  }, [page, limit, keyword, statusFilter, submitedDate]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const changePage = (val) => {
    setPage(val);
    wrapperRef.current.scrollIntoView({ behaviour: 'smooth' });
  };

  const changeRowsPerPage = (page) => {
    setRowPerPage(page);
    setPage(1);
    setLimit(page.value);
    wrapperRef.current.scrollIntoView({ behaviour: 'smooth' });
  };

  return (
    <Fragment>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label:
                    'Laporan Implementasi Komitmen Perlindungan Lingkungan & Sosial',
                  path: `/report/commitment`,
                },
              ]}
            />
            <div className="mt-6 flex flex-row items-center">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span
                    className="font-semibold text-[24px] text-gray-900"
                    ref={wrapperRef}
                  >
                    Laporan Implementasi Komitmen Perlindungan Lingkungan &
                    Sosial
                  </span>
                </div>
              </div>
              <div className="flex-1 text-right">
                <Button
                  type="button"
                  className="rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white px-4 py-2.5"
                  label={
                    <div className="flex items-center gap-2">
                      <RiAddLine />
                      <span className="font-semibold text-sm">
                        Tambah Laporan
                      </span>
                    </div>
                  }
                  onClick={() => navigate('/report/commitment/form')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4 p-3 rounded-xl bg-white border border-gray-200">
          <div className="flex flex-col gap-5">
            <div className="flex gap-5">
              <div className="w-full">
                <SearchInput onChange={onSearch} />
              </div>
            </div>
          </div>
          {isFetching ? (
            <div className="min-h-[500px] flex items-center justify-center">
              <Spinner theme="primary" />
            </div>
          ) : list.data?.data?.data?.length > 0 ? (
            <div className="space-y-4">
              {list?.data?.data?.data?.map((x, key) => {
                const month = [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'Mei',
                  'Jun',
                  'Jul',
                  'Agu',
                  'Sep',
                  'Okt',
                  'Nov',
                  'Des',
                ];
                const splitSubmitedDate = x?.submited_date?.split('/');
                let activityDate = x?.activity_date;
                if (Array.isArray(x?.activity_date)) {
                  activityDate = x?.activity_date?.map((p, x) => {
                    const reformat = `${new Date(p).getDate()} ${
                      month[new Date(p).getMonth()]
                    } ${new Date(p).getFullYear()}`;
                    return reformat;
                  });
                  activityDate = activityDate?.join(' - ');
                }

                return (
                  <div
                    key={key}
                    className="p-5 rounded-xl border border-gray-200 space-y-3"
                  >
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-row items-center">
                        <div className="flex flex-col flex-1 gap-4">
                          <div className="flex-1">
                            <span className="font-bold text-sm">
                              Tanggal Submit Laporan:{' '}
                              {moment(x.created_at).format('DD/MM/YYYY')}
                            </span>
                          </div>
                          <div className="flex-1 text-sm">
                            <div>Periode Laporan : </div>
                            <div className="font-bold">{x.periode}</div>
                          </div>
                          <div className="flex-1 text-sm">
                            <div>Tahun Laporan : </div>
                            <div className="font-bold">{x.tahun}</div>
                          </div>
                        </div>

                        <div className="flex flex-col flex-1 gap-4">
                          <div className="flex-1">
                            <span>
                              <StatusBadge status={x.status} />
                            </span>
                          </div>
                          <div className="flex-1 text-sm">
                            <div>Judul Proposal : </div>
                            <div className="font-bold">{x.cfp_title}</div>
                          </div>
                          <div className="flex-1 text-sm" />
                        </div>

                        <div className="flex flex-col flex-1 gap-4">
                          {x.status === 'Menunggu Verifikasi' && (
                            <div>
                              <Button
                                type="button"
                                size="sm"
                                className="py-2 px-3.5 bg-red-50 rounded-lg w-full h-full"
                                onClick={() => {
                                  navigate(`/report/commitment/${x?._id}/edit`);
                                }}
                                label={
                                  <div className="flex items-center gap-2 text-red-700 text-xs font-semibold">
                                    <span>Ubah Laporan</span>
                                  </div>
                                }
                              />
                            </div>
                          )}
                          <div>
                            <Button
                              type="button"
                              size="sm"
                              className="py-2 px-3.5 border border-gray-300 bg-white rounded-lg w-full h-full"
                              onClick={() =>
                                navigate(`/report/commitment/${x?._id}`)
                              }
                              label={
                                <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                                  <AiOutlineEye size={20} />
                                  <span>Lihat Detail</span>
                                </div>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <hr />
              <div className="flex items-center justify-end px-4 pb-4 pt-3">
                <div className="flex space-x-3 items-center">
                  <p className="mb-0 text-gray-600 text-sm">Show Data</p>
                  <Select
                    value={rowPerPage}
                    optionsData={rowPerPageOptions}
                    onChange={changeRowsPerPage}
                    menuPortalTarget={document.body}
                  />
                  <p className="mb-0 text-gray-600 text-sm">{`${
                    (page - 1) * rowPerPage.value || 1
                  } - ${
                    page <= 1 && list.data?.data?.total <= rowPerPage.value
                      ? list.data?.data?.total
                      : page * rowPerPage.value
                  } data from ${list.data?.data?.total} data.`}</p>
                </div>
                <div className="flex flex-1 justify-end">
                  <TablePagination
                    page={page}
                    totalPage={list.data?.data?.lastPage}
                    onChange={changePage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center w-full mt-5">
              <p>No Data</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ReportActivity;
