import { Button, ConfirmationModal, Spinner } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { RiAddLine, RiDownloadLine, RiPencilLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import Select from "../CallForProposal/components/Select";
import CustomDatepicker from "../CallForProposal/components/Datepicker";
import SearchInput from "../CallForProposal/components/SearchInput";
import { debounce } from "lodash";
import { FaRegPaperPlane } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { getDirectSubmissionList } from "services/danaProgram/directSubmissionService";
import { formatCurrency } from "helpers";
import { createSearchParams } from "react-router-dom";
import TablePagination from "components/atoms/Pagination";

const rowPerPageOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

const STATUS_ARRAY = [
  {
    label: "Menunggu Persetujuan Direktur Proyek",
    value: "NEED_APPROVAL",
    classContainer: "bg-warning-50 text-warning-700",
    classDot: "bg-warning-500",
  },
  {
    label: "Menunggu Persetujuan Direktur Penyaluran",
    value: "NEED_APPROVAL_DIRECTOR",
    classContainer: "bg-warning-50 text-warning-700",
    classDot: "bg-warning-500",
  },
  {
    label: "Disetujui",
    value: "APPROVED",
    classContainer: "bg-primary-50 text-primary-700",
    classDot: "bg-primary-500",
  },
  {
    label: "Ditolak",
    value: "REJECTED",
    classContainer: "bg-red-50 text-red-700",
    classDot: "bg-red-500",
  },
];

const StatusBadge = ({ status }) => {
  const statusMatched = STATUS_ARRAY.find(({ value }) => value === status);

  const defaultWrapperClass = statusMatched?.classContainer ?? "";
  const defaultDotClass = statusMatched?.classDot ?? "";

  return (
    <div
      className={`rounded-2xl flex items-center border-transparent p-2 text-xs ${defaultWrapperClass}`}
    >
      <div
        className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`}
      />
      <div className="flex-1 text-center whitespace-nowrap">
        {statusMatched?.label ?? "-"}
      </div>
    </div>
  );
};

const InsentifList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowPerPage, setRowPerPage] = useState({ value: 10, label: "10" });
  const [modal, setModal] = useState({
    title: "",
    description: "",
    type: "",
    open: false,
    data: null,
    cancelLabel: "",
    confirmLabel: "",
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const changePage = (val) => {
    setPage(val);
  };

  const changeRowsPerPage = (page) => {
    setRowPerPage(page);
    setPage(1);
    setLimit(page.value);
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      "get-list-insentif",
      page,
      limit,
      keyword,
      statusFilter,
      startDate,
      endDate,
    ],
    queryFn: async () => {
      const res = await getDirectSubmissionList({
        page,
        limit,
        name: keyword,
        start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
        end_date: endDate && moment(endDate).format("YYYY-MM-DD"),
        status: statusFilter?.value,
      });

      return res.data.data;
    },
  });

  const renderButton = (status, id) => {
    let element = <></>;
    switch (status) {
      case "Menunggu Persetujuan":
        element = (
          <>
            <Button
              type="button"
              size="sm"
              className="p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
              onClick={() =>
                navigate({
                  pathname: `/request-insentif/form`,
                  search: createSearchParams({
                    id,
                  }).toString(),
                })
              }
              label={
                <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                  <RiPencilLine size={20} />
                  <span>Edit</span>
                </div>
              }
            />
          </>
        );
        break;
      case "Disetujui":
      case "Ditolak":
        element = (
          <Button
            type="button"
            size="sm"
            className="py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
            onClick={() =>
              navigate({
                pathname: `/request-insentif/form`,
                search: createSearchParams({
                  id,
                  mode: "detail",
                }).toString(),
              })
            }
            label={
              <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                <AiOutlineEye size={20} />
                <span>Lihat Detail</span>
              </div>
            }
          />
        );
        break;
      default:
        element = <></>;
        break;
    }
    return element;
  };

  return (
    <>
      <ConfirmationModal
        useGraphic
        open={modal.open && modal.type === "confirm"}
        onCancel={() => {
          setModal((curr) => ({
            ...curr,
            type: "",
            title: "",
            description: "",
            open: false,
            data: null,
          }));
        }}
        cancelLabel={modal.cancelLabel}
        confirmLabel={modal.confirmLabel}
        onSubmit={() => console.log("first")}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {modal.title}
          </div>
          <div className="text-sm text-gray-600">{modal.description}</div>
        </div>
      </ConfirmationModal>
      <div className="bg-white rounded-xl p-10">
        <div className="flex flex-col w-full">
          <BreadCrumbs
            routes={[
              {
                label: "Pengajuan Langsung / Insentif",
                path: `/request-insentif`,
              },
            ]}
          />
          <div className="my-6 flex flex-row">
            <div className="flex-1">
              <div className="flex flex-col">
                <span className="font-semibold text-[24px] text-gray-900">
                  Daftar Pengajuan Langsung / Insentif
                </span>
              </div>
            </div>
            <div className="flex-1 text-right">
              <Button
                type="button"
                className="rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white px-4 py-2.5"
                label={
                  <div className="flex items-center gap-2">
                    <RiAddLine />
                    <span className="font-semibold text-sm">
                      Tambah Pengajuan
                    </span>
                  </div>
                }
                onClick={() =>
                  navigate(
                    location?.pathname?.includes("/beneficiaries/insentif")
                      ? "/beneficiaries/insentif/form"
                      : "/request-insentif/form"
                  )
                }
              />
            </div>
          </div>

          <div className="space-y-4 p-3 rounded-xl bg-white border border-gray-200">
            <div className="flex gap-5">
              <div className="w-1/3">
                <SearchInput onChange={onSearch} />
              </div>
              <div className="w-1/3">
                <CustomDatepicker
                  placeholder="Tanggal Pengajuan"
                  // value={dateFilter}
                  // onChange={(e) => console.log(e)}
                  onChange={(dates) => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  onClear={() => {
                    setStartDate(null);
                    setEndDate(null);
                  }}
                  selectsRange
                />
              </div>
              <div className="w-1/3">
                <Select
                  placeholder="Status"
                  value={statusFilter}
                  optionsData={STATUS_ARRAY}
                  onChange={setStatusFilter}
                  isClearable={true}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
            {isLoading ? (
              <div className="w-full flex justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                {data?.data?.map((item, index) => (
                  <div className="w-full rounded-xl border border-gray-200 p-5">
                    <div className="flex justify-between">
                      <p className="font-semibold">
                        Tanggal Permohonan :{" "}
                        {moment(item?.created_at).format("DD/MM/YYYY")}
                      </p>
                      <StatusBadge status={item?.status} />
                    </div>
                    <div key={index} className="my-5 grid grid-cols-4 gap-3">
                      <div className="flex flex-col gap-5">
                        <div className="flex flex-col">
                          <p>Judul Proposal :</p>
                          <p className="font-semibold">{item?.program_name}</p>
                        </div>
                        <div className="flex flex-col">
                          <p>Proyek :</p>
                          <p className="font-semibold">
                            {item?.project?.label || "-"}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-3 col-span-2">
                        <div className="flex flex-col">
                          <p>Output :</p>
                          <p className="font-semibold">
                            {item?.tor?.map((item) => (
                              <li>{item?.output?.label || "-"}</li>
                            ))}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p>Kegiatan Utama :</p>
                          <p className="font-semibold">
                            {item?.activity?.map((item) => (
                              <li>{item?.main_activity?.label || "-"}</li>
                            ))}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-col">
                          <p>Tanggal Kegiatan :</p>
                          <p className="font-semibold">
                            {item?.activity?.map((item) => (
                              <li>
                                {moment(item?.activity_date).format(
                                  "DD MMM YYYY"
                                )}
                              </li>
                            ))}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p>Realisasi Biaya :</p>
                          <p className="font-semibold">
                            {item?.activity
                              ?.map((item) => item.total_budget)
                              .reduce(
                                (prev, curr) => formatCurrency(prev + curr),
                                0
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="flex justify-end mt-3">
                      {renderButton(item?.status, item?._id)}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end px-4 pb-4 pt-3">
          <div className="flex space-x-3 items-center">
            <p className="mb-0 text-gray-600 text-sm">Show Data</p>
            <Select
              value={rowPerPage}
              optionsData={rowPerPageOptions}
              onChange={changeRowsPerPage}
              menuPortalTarget={document.body}
            />
            <p className="mb-0 text-gray-600 text-sm">{`${(page - 1) * rowPerPage.value || 1
              } - ${page <= 1 && data?.total <= rowPerPage.value
                ? data?.total
                : page * rowPerPage.value
              } data from ${data?.total} data.`}</p>
          </div>
          <div className="flex flex-1 justify-end">
            <TablePagination
              page={page}
              totalPage={data?.lastPage}
              onChange={changePage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InsentifList;
