export const PERIOD_OPTIONS = [
  {
    value: 'Quartal',
    label: 'Quartal',
  },
  {
    value: 'Semester',
    label: 'Semester',
  },
  {
    value: 'Tahunan',
    label: 'Tahunan',
  },
];

export const REPORT_PERIOD_OPTIONS = [
  {
    value: 'Quartal 1',
    label: 'Quartal 1',
  },
  {
    value: 'Quartal 2',
    label: 'Quartal 2',
  },
  {
    value: 'Quartal 3',
    label: 'Quartal 3',
  },
  {
    value: 'Semester 1',
    label: 'Semester 1',
  },
  {
    value: 'Semester 2',
    label: 'Semester 2',
  },
  {
    value: 'Tahunan',
    label: 'Tahunan',
  },
];
