import React, { useState, Fragment, useEffect } from 'react';
import { Button, InputForm } from 'components';
import CardForm from 'components/molecules/CardForm';
import AtomSelect from 'components/atoms/Select';
import AtomDatePicker from 'components/atoms/Datepicker';
import RangeDatepicker from 'components/atoms/RangeDatepicker';
import ReactSelectCreatable from 'react-select/creatable';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import AccordionButton from 'components/atoms/AccordionButton';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import _ from 'lodash';
import { BiArrowBack } from 'react-icons/bi';
import FileDetail from 'components/molecules/FileDetail';
import { useMutation } from '@tanstack/react-query';
import { postDetailActivityReport } from 'services/danaProgram/callForProposalService';
import { useNavigate } from 'react-router';
import { formatCurrency } from 'helpers';

const DetailActivity = ({ data = null, onBack, onNext }) => {
  const defaultValues = {
    document: undefined,
    detail_activity: [
      {
        name: "",
        implementation_date: null,
        realized_date: ""
      }
    ]
  };
  const [accordion, setAccordion] = useState([false]);
  const [fileDoc, setFileDoc] = useState([]);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues
  });

  const { control } = methods;

  const {
    fields: detailActivityField,
    append: appendDetailActivityField
  } = useFieldArray({
    control,
    name: 'detail_activity',
  });

  const accordionToggle = (key) => {
    let temp = [...accordion];
    temp[key] = !temp[key];

    setAccordion(temp);
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      methods.reset({
        id: data?._id,
        detail_activity: data?.detail_activity.map(detail => ({
          ...detail,
          implementation_date: new Date(detail.implementation_date)
        })),
        document: data?.detail_activity_document
      });

      setFileDoc(data?.detail_activity_document)
    }
  }, [data]);

  const onSubmit = (data) => {
    postData.mutate(data);
  }

  const postData = useMutation({
    mutationKey: ["post-data-detail-activity"],
    mutationFn: async (payload) => {
      const res = await postDetailActivityReport(payload);
      return res;
    },
    onSuccess: async (res) => {
      navigate("/proposal/call-for-proposal")
    }
  })

  const ActivitySection = ({ activityKey, accordion, setAccordion }) => {
    return (
      <Fragment>
        <AccordionButton
          label={`Detail Kegiatan ${activityKey + 1}`}
          isOpen={accordion?.[activityKey]}
          onClick={() => setAccordion(activityKey)}
        />
        <div
          className={`${accordion?.[activityKey]
            ? "opacity-1 h-full visible"
            : "opacity-0 h-0 invisible max-h-0"
            }`}
          style={{ transition: "all .2s" }}
        >
          <div className="space-y-4">
            <InputForm
              controllerName={`detail_activity.${activityKey}.name`}
              label={"Detail Kegiatan"}
              className="w-full text-sm"
            />
            <AtomDatePicker
              label={"Tanggal Pelaksanaan"}
              controllerName={`detail_activity.${activityKey}.implementation_date`}
            />
            <InputForm
              controllerName={`detail_activity.${activityKey}.realized`}
              label={"Nilai Realisasi Biaya (Rp)"}
              className="w-full text-sm"
            />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <FormProvider {...methods}>
      <CardForm label="Tahapan Pelaksanaan & biaya kegiatan">
        {detailActivityField.map((field, key) => (
          <ActivitySection
            key={"activity-section-" + key}
            activityKey={key}
            accordion={accordion}
            setAccordion={accordionToggle}
          />
        ))}
        <div className="flex justify-end mt-4 w-full">
          <Button
            className="bg-white border-[#D0D5DD]"
            onClick={() => {
              appendDetailActivityField({ name: "", implementation_date: "", realized: "" })
            }}
            label={
              <div className="flex items-center self-center">
                <FaPlus size={16} className="mr-2" />
                Detail Kegiatan
              </div>
            }
          />
        </div>
      </CardForm>
      <CardForm label="Dokumen Pendukung">
        <div className="space-y-2">
          <div className="text-[#1D2939] font-semibold text-sm">
            Upload Dokumen Bukti Penggunaan Biaya
          </div>
          {fileDoc.length > 0 ? (
            fileDoc.map((item, key) => (
              <FileDetail
                key={'file-doc-' + key}
                file={item}
                onRemove={() => {
                  const filtered = fileDoc.filter(
                    (file) => file.fileId !== item.fileId
                  );

                  setFileDoc(filtered);
                }}
              />
            ))
          ) : (
            <InputFormDropzone
              name="document"
              accept={{
                "image/png": [],
                "image/jpeg": [],
                "application/pdf": [],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [],
              }}
              maxFiles={10}
              maxSize={10}
              multiple={true}
              informationText="PNG, JPG, PDF, Docx"
            />
          )}
        </div>
      </CardForm>
      <div className="flex justify-end space-x-4 sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
        <Button
          onClick={onBack}
          label={
            <div className="flex items-center gap-2">
              <BiArrowBack />
              <span>Kembali</span>
            </div>
          }
          size="md"
          className="bg-white"
        />
        <Button
          onClick={() => methods.handleSubmit(onSubmit)()}
          label="Selanjutnya"
          size="md"
          className="bg-primary-600 hover:bg-primary-600/90 text-white"
        />
      </div>
    </FormProvider>
  );
}

export default DetailActivity;