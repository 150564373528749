/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from 'components';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import AtomSelect from 'components/atoms/Select';
import CardForm from 'components/molecules/CardForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiQuestionLine } from 'react-icons/ri';
import AtomDatePicker from 'components/atoms/Datepicker';
import validationSchema from './validationSchema';
import _ from 'lodash';
import CurrentAssetOtherAsset from './Form/currentAssetOtherAsset';
import OtherAsset from './Form/otherAsset';
import FixedAssetOtherAsset from './Form/fixedAssetOtherAsset';
import { useMutation } from '@tanstack/react-query';
import { fileUpload, getFile } from 'services/danaProgram/fileService';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const InformasiKeuanganLembaga = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
  draftLemtara,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get('readonly') === 'true';
  const [chipAmount, setChipAmount] = useState(['Rp.0', 'Rp.0', 'Rp.0']);
  const [chipDebt, setChipDebt] = useState(['Rp.0', 'Rp.0', 'Rp.0']);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    trigger,
  } = methods;

  const onUploadFile = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response.data.data?.url,
        id: response.data.data?.id,
        type: response.data.data?.mimeType,
        name: response.data.data?.name,
        size: response?.data?.data?.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  function convertToInteger(str) {
    if (str === '' || str == null) return 0;
    const cleanedStr = str.replace(/\./g, '');
    const result = parseInt(cleanedStr, 10);
    return result;
  }

  const onLoadFile = useMutation(async ({ data, key, array = false }) => {
    try {
      let newResult = [];
      if (array) {
        for (const p in data) {
          let reportFinancial = data[p]?.url_file_report_financial;
          var temp;
          if (data[p]?.url_file_report_financial.id) {
            reportFinancial = await getFile(
              data[p]?.url_file_report_financial.id
            );
            temp = {
              url: reportFinancial?.data?.data?.url,
              id: reportFinancial?.data?.data?.id,
              type: reportFinancial?.data?.data?.mimeType,
              name: reportFinancial?.data?.data?.name,
              size: reportFinancial?.data?.data?.size,
            };
          }
          newResult.push({
            year: data[p]?.year,
            current_asset: data[p]?.current_asset,
            fixed_asset: data[p]?.fixed_asset,
            other_asset: data[p]?.other_asset,
            total_asset: (data[p]?.total_asset ?? '')?.replaceAll('Rp. ', ''),
            total_net_worth: data[p]?.total_net_worth,
            short_term_debt: data[p]?.short_term_debt,
            long_term_debt: data[p]?.long_term_debt,
            total_net_income: data[p]?.total_net_income,
            other_asset_amount: data[p]?.other_asset_amount,
            total_income: data[p]?.total_income,
            total_incurred: data[p]?.total_incurred,
            is_kap: data[p]?.is_kap,
            kap_description: data[p]?.kap_description,
            kap: data[p].kap,
            kap_value: data[p].kap_value,
            url_file_report_financial: temp,
          });
        }
      } else {
        let reportResult = await getFile(data);
        reportResult = {
          url: reportResult?.data?.result?.url,
          id: reportResult?.data?.result?.file?._id,
          type: reportResult?.data?.result?.file?.mimeType,
          name: reportResult?.data?.result?.file?.name,
          size: reportResult?.data?.result?.file?.size,
        };
        newResult = reportResult;
      }

      newResult?.map((data, index) => {
        setChipAmount((prevArray) =>
          prevArray.map((item, i) =>
            i === index
              ? 'Rp. ' +
                convertMoney(
                  convertToInteger(data?.current_asset?.total) +
                    convertToInteger(data?.fixed_asset?.total) +
                    convertToInteger(data?.other_asset?.total)
                )
              : item
          )
        );
        setChipDebt((prevArray) =>
          prevArray.map((item, i) =>
            i === index
              ? 'Rp. ' +
                convertMoney(
                  convertToInteger(data?.long_term_debt?.total) +
                    convertToInteger(data?.short_term_debt?.total)
                )
              : item
          )
        );
      });

      setValue(key, newResult);
      // clearErrors();

      trigger();
    } catch (error) {}
  });

  const {
    fields: financialReportFields,
    append: appendFinancialReport,
    remove: removeFinancialReport,
  } = useFieldArray({
    control,
    name: 'financial_report',
  });

  useEffect(() => {
    if (draftData && Object.keys(draftData).length > 0) {
      Object.keys(draftData).forEach((key) => {
        if (key === 'financial_report' && draftData.financial_report) {
          onLoadFile.mutate({ data: draftData[key], key, array: true });
        } else {
          if (draftData?.year_range) {
            if (typeof draftData?.year_range === 'number') {
              let temp = new Date((draftData?.year_range + 2).toString());
              setValue('yearRange', temp);
            } else {
              let temp = new Date(draftData?.year_range?.end_year.toString());
              setValue('yearRange', temp);
            }
          }
        }
      });
    }
  }, [draftData]);

  useEffect(() => {
    if (
      watch(`yearRange`) !== null &&
      moment(watch(`yearRange`)).year() !== draftData?.year_range?.end_year
    ) {
      const yearRange = watch(`yearRange`);
      const startYear = new Date(yearRange).getFullYear() - 2;
      const endYear = new Date(yearRange).getFullYear();
      let rangeYear = _.range(Number(startYear), Number(endYear) + 1);
      setValue(`year_range.start_year`, startYear);
      setValue(`year_range.end_year`, endYear);
      let arrSetDataYear = [];
      for (const p in rangeYear) {
        arrSetDataYear.push({
          year: rangeYear[p],
        });
      }
      setValue(`financial_report`, arrSetDataYear);
    }
  }, [watch('yearRange')]);

  const sumAssetTotal = (index) => {
    let total = 0;

    const current_asset =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.current_asset.total`)
      ) || 0;
    const fixed_asset =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.fixed_asset.total`)
      ) || 0;
    const other_asset =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.other_asset_amount`)
      ) || 0;

    total = current_asset + fixed_asset + other_asset;

    methods.setValue(
      `financial_report.${index}.total_asset`,
      convertMoney(total)
    );
  };

  const sumTotalDebt = (index) => {
    let total = 0;

    const short_term_debt =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.short_term_debt.total`)
      ) || 0;
    const long_term_debt =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.long_term_debt.total`)
      ) || 0;

    total = short_term_debt + long_term_debt;

    methods.setValue(
      `financial_report.${index}.total_debt`,
      convertMoney(total)
    );
  };

  const sumCurrentAssetTotal = (index) => {
    let total = 0;
    let otherAssetSum = 0;
    const cash =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.current_asset.cash`)
      ) || 0;
    const equipment =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.current_asset.equipment`)
      ) || 0;
    const supply =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.current_asset.supply`)
      ) || 0;
    const receivables =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.current_asset.debts`)
      ) || 0;

    const downPayment =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.current_asset.advance_payment_program`)
      ) || 0;

    const otherAsset =
      watch(`financial_report.${index}.current_asset.other_asset`) || [];
    if (otherAsset.length > 0) {
      otherAssetSum = otherAsset.reduce(
        (acc, p) => acc + convertFormattedValueToNumber(p?.value) || 0,
        0
      );
    }

    total =
      cash + equipment + supply + receivables + otherAssetSum + downPayment;

    setValue(
      `financial_report.${index}.current_asset.total`,
      convertMoney(total)
    );
    sumTotalNetWorth(index);
    sumAssetTotal(index);
  };

  const sumFixedAssetTotal = (index) => {
    let total = 0;
    let otherAssetSum = 0;
    const equipment =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.fixed_asset.equipment`)
      ) || 0;

    const wip =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.fixed_asset.wip`)
      ) || 0;
    const land_and_buildings =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.fixed_asset.land_and_buildings`)
      ) || 0;
    const otherAsset =
      watch(`financial_report.${index}.fixed_asset.other_asset`) || [];
    if (otherAsset.length > 0) {
      otherAssetSum = otherAsset.reduce(
        (acc, p) => acc + convertFormattedValueToNumber(p?.value) || 0,
        0
      );
    }
    total = wip + equipment + land_and_buildings + otherAssetSum;

    setValue(
      `financial_report.${index}.fixed_asset.total`,
      convertMoney(total)
    );

    sumTotalNetWorth(index);
    sumAssetTotal(index);
  };

  const sumOtherAssetTotal = (index) => {
    let otherAssetSum = 0;
    const otherAsset = watch(`financial_report.${index}.other_asset`) || [];
    if (otherAsset.length > 0) {
      otherAssetSum = otherAsset.reduce(
        (acc, p) => acc + convertFormattedValueToNumber(p?.value) || 0,
        0
      );
    }
    setValue(
      `financial_report.${index}.other_asset_amount`,
      convertMoney(otherAssetSum)
    );
    sumTotalNetWorth(index);
    sumAssetTotal(index);
  };

  const sumShortTermTotal = (index) => {
    let total = 0;
    const cash =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.short_term_debt.cash`)
      ) || 0;
    const equipment =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.short_term_debt.equipment`)
      ) || 0;
    const other_debt =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.short_term_debt.other_debt`)
      ) || 0;
    const supply =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.short_term_debt.supply`)
      ) || 0;
    const receivables =
      convertFormattedValueToNumber(
        watch(`financial_report.${index}.short_term_debt.receivables`)
      ) || 0;
    total = cash + equipment + other_debt + supply + receivables;
    setValue(
      `financial_report.${index}.short_term_debt.total`,
      convertMoney(total)
    );
    sumTotalNetWorth(index);
    sumTotalDebt(index);
  };

  const sumLongTermTotal = (index) => {
    let total = 0;
    const long_term_debt = convertFormattedValueToNumber(
      watch(`financial_report.${index}.long_term_debt.debt`) || 0
    );
    total = long_term_debt;
    setValue(
      `financial_report.${index}.long_term_debt.total`,
      convertMoney(total)
    );
    sumTotalNetWorth(index);

    sumTotalDebt(index);
  };

  const sumTotalNetWorth = (index) => {
    let totalNetWorth = 0;
    const current_asset_total =
      convertFormattedValueToNumber(
        getValues(`financial_report.${index}.current_asset.total`)
      ) || 0;
    const fixed_asset_total =
      convertFormattedValueToNumber(
        getValues(`financial_report.${index}.fixed_asset.total`)
      ) || 0;
    const other_asset_amount =
      convertFormattedValueToNumber(
        getValues(`financial_report.${index}.other_asset_amount`)
      ) || 0;
    const short_term_debt_total =
      convertFormattedValueToNumber(
        getValues(`financial_report.${index}.short_term_debt.total`)
      ) || 0;
    const long_term_debt_total =
      convertFormattedValueToNumber(
        getValues(`financial_report.${index}.long_term_debt.total`)
      ) || 0;
    totalNetWorth =
      current_asset_total +
      fixed_asset_total +
      other_asset_amount -
      (short_term_debt_total + long_term_debt_total);

    setValue(
      `financial_report.${index}.total_net_worth`,
      convertMoney(totalNetWorth)
    );
  };

  const sumTotalNetIncome = (index) => {
    let totalNetIncome = 0;
    if (draftLemtara?.label?.includes('Non')) {
      const total_income =
        convertFormattedValueToNumber(
          watch(`financial_report.${index}.total_income`)
        ) || 0;
      const total_incurred =
        convertFormattedValueToNumber(
          watch(`financial_report.${index}.total_incurred`)
        ) || 0;
      const program_costs =
        convertFormattedValueToNumber(
          watch(`financial_report.${index}.program_costs`)
        ) || 0;
      const non_program_costs =
        convertFormattedValueToNumber(
          watch(`financial_report.${index}.non_program_costs`)
        ) || 0;

      setValue(
        `financial_report.${index}.total_program_costs`,
        convertFormattedValueToNumber(program_costs + non_program_costs)
      );

      const total_program_costs =
        convertFormattedValueToNumber(
          watch(`financial_report.${index}.total_program_costs`)
        ) || 0;

      totalNetIncome =
        total_income + total_incurred - (program_costs + non_program_costs);
    } else {
      const total_income =
        convertFormattedValueToNumber(
          watch(`financial_report.${index}.total_income`)
        ) || 0;
      const total_incurred =
        convertFormattedValueToNumber(
          watch(`financial_report.${index}.total_incurred`)
        ) || 0;
      totalNetIncome = total_income - total_incurred;
    }

    setValue(
      `financial_report.${index}.total_net_income`,
      convertMoney(totalNetIncome)
    );
  };

  function convertFormattedValueToNumber(formattedValue) {
    if (
      formattedValue === '' ||
      !formattedValue ||
      typeof formattedValue !== 'string'
    )
      return null;
    const numericString = formattedValue?.replace(/[^0-9]/g, '');

    const numberValue = parseInt(numericString, 10);

    return numberValue;
  }

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes('.') ? oriValue.split('.').join('') : oriValue;
    let modifValue = '';
    let count = 1;
    if (oriValue !== '' && oriValue.length > 3) {
      const splitValue = oriValue.split('');
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  function onSubmitForm(data) {
    if (onSubmit) {
      onSubmit(data);
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={'Informasi Rekening'}>
          <>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                <RiQuestionLine />
                Masukan data keuangan 3 taun terakhir
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <AtomDatePicker
                  controllerName={'yearRange'}
                  label="Pilih Range Tahun"
                  required={true}
                  placeholder="2020-2022"
                  format={'yyyy'}
                  rangeYear
                  isRangeDatePicker={false}
                  asText={isReadonly}
                />
              </div>
              <div className="flex-1 pt-2"></div>
            </div>
          </>
        </CardForm>
        {financialReportFields &&
          financialReportFields.map((data, index) => {
            return (
              <CardForm
                label={`Laporan Keuangan Lembaga - Tahun ${data.year}`}
                buttonCollapse={true}
                key={data.id}
              >
                <>
                  <CardForm
                    label={`Aktiva/Aset`}
                    sideLabel={`Rp. ${watch(
                      `financial_report.${index}.total_asset`
                    )}`}
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    <>
                      <span className="font-semibold text-[#01A24A]">
                        A. Aktiva Lancar
                      </span>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.current_asset.total`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Jumlah (A)'}
                            info={
                              'Jumlah dihitung berdasarkan data yang dimasukkan.'
                            }
                            placeholder={'0'}
                            disabled
                          />
                        </div>
                        <div className="flex-1"></div>
                      </div>

                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={
                              'Setara Kas termasuk saldo rekening di bank dan deposito yang dapat dicairkan dalam waktu kurang dari tiga bulan'
                            }
                            controllerName={`financial_report.${index}.current_asset.cash`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Kas dan setara kas'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.current_asset.cash`,
                                convertMoney(e.target.value)
                              );
                              sumCurrentAssetTotal(index);
                            }}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={
                              'Barang habis pakai yang digunakan untuk kegiatan operasional sehari-hari seperti ATK, alat kebersihan, dan suku cadang'
                            }
                            controllerName={`financial_report.${index}.current_asset.equipment`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Perlengkapan'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.current_asset.equipment`,
                                convertMoney(e.target.value)
                              );
                              sumCurrentAssetTotal(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={'Barang yang akan diperjualbelikan'}
                            controllerName={`financial_report.${index}.current_asset.supply`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Persediaan'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.current_asset.supply`,
                                convertMoney(e.target.value)
                              );
                              sumCurrentAssetTotal(index);
                            }}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={
                              'Jumlah seluruh piutang baik dari pelanggan/nasabah/anggota maupun pegawai'
                            }
                            controllerName={`financial_report.${index}.current_asset.debts`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Piutang'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.current_asset.debts`,
                                convertMoney(e.target.value)
                              );
                              sumCurrentAssetTotal(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        {draftLemtara?.label?.includes('Non') ? (
                          <div className="flex-1 pt-2">
                            <InputForm
                              showPrefix={true}
                              prefix={'Rp. '}
                              desc={'Jumlah Uang Muka Program'}
                              controllerName={`financial_report.${index}.current_asset.advance_payment_program`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md`}
                              label={'Uang Muka Program'}
                              placeholder={'0'}
                              disabled={isReadonly}
                              onChangeInput={(e) => {
                                setValue(
                                  `financial_report.${index}.current_asset.advance_payment_program`,
                                  convertMoney(e.target.value)
                                );
                                sumCurrentAssetTotal(index);
                              }}
                              required={true}
                            />
                          </div>
                        ) : (
                          <div className="flex-1 pt-2"></div>
                        )}
                      </div>
                      <div className="border-b" />
                      <span className="font-semibold text-[#01A24A]">
                        Data Aset Lancar Lainnya
                      </span>

                      <CurrentAssetOtherAsset
                        draftData={draftData}
                        nestIndex={index}
                        sumCurrentAssetTotal={sumCurrentAssetTotal}
                        convertMoney={convertMoney}
                        setValue={setValue}
                      />

                      <div className="border-b" />
                      <span className="font-semibold text-[#01A24A]">
                        B. AKTIVA TETAP
                      </span>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.fixed_asset.total`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Jumlah (B)'}
                            info={
                              'Jumlah dihitung berdasarkan data yang dimasukkan.'
                            }
                            placeholder={'0'}
                            disabled
                          />
                        </div>
                        <div className="flex-1"></div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={
                              'Seperti furnitur, kendaraan, dan peralatan elektronik'
                            }
                            controllerName={`financial_report.${index}.fixed_asset.equipment`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Peralatan dan Mesin'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.fixed_asset.equipment`,
                                convertMoney(e.target.value)
                              );
                              sumFixedAssetTotal(index);
                            }}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={<br></br>}
                            controllerName={`financial_report.${index}.fixed_asset.wip`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Pekerjaan Dalam Proses'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.fixed_asset.wip`,
                                convertMoney(e.target.value)
                              );
                              sumFixedAssetTotal(index);
                            }}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={<br></br>}
                            controllerName={`financial_report.${index}.fixed_asset.land_and_buildings`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Tanah dan bangunan'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.fixed_asset.land_and_buildings`,
                                convertMoney(e.target.value)
                              );
                              sumFixedAssetTotal(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className="border-b" />

                      <span className="font-semibold text-[#01A24A]">
                        Aset Tetap Lainnya
                      </span>

                      <FixedAssetOtherAsset
                        draftData={draftData}
                        nestIndex={index}
                        sumFixedAssetTotal={sumFixedAssetTotal}
                        convertMoney={convertMoney}
                        setValue={setValue}
                      />

                      <span className="font-semibold text-[#01A24A]">
                        C. AKTIVA LAINNYA (Selain Aset Lancar dan Aset Tetap,
                        contohnya Aset Tidak Berwujud)
                      </span>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.other_asset_amount`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Jumlah (C)'}
                            info={
                              'Jumlah dihitung berdasarkan data yang dimasukkan.'
                            }
                            placeholder={'0'}
                            disabled
                          />
                        </div>
                        <div className="flex-1"></div>
                      </div>

                      <OtherAsset
                        draftData={draftData}
                        nestIndex={index}
                        sumOtherAssetTotal={sumOtherAssetTotal}
                        convertMoney={convertMoney}
                        setValue={setValue}
                      />

                      <div className="border-b" />
                    </>
                  </CardForm>

                  <CardForm
                    label={'Pasiva/Kewajiban/Utang'}
                    sideLabel={
                      watch(`financial_report.${index}.total_debt`)
                        ? `Rp ${watch(`financial_report.${index}.total_debt`)}`
                        : chipDebt[index]
                    }
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    <>
                      <span className="font-semibold text-[#01A24A]">
                        D. UTANG JANGKA PENDEK (Kurang dari 1 Tahun)
                      </span>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.short_term_debt.total`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Jumlah (C)'}
                            info={
                              'Jumlah dihitung berdasarkan data yang dimasukkan.'
                            }
                            placeholder={'0'}
                            required={true}
                            disabled
                          />
                        </div>
                        <div className="flex-1"></div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.short_term_debt.cash`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Utang Dagang'}
                            desc={`Utang yang berkaitan dengan kegiatan usaha`}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.short_term_debt.cash`,
                                convertMoney(e.target.value)
                              );
                              sumShortTermTotal(index);
                            }}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={'Utang yang berkaitan dengan pajak'}
                            controllerName={`financial_report.${index}.short_term_debt.equipment`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Utang Pajak'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.short_term_debt.equipment`,
                                convertMoney(e.target.value)
                              );
                              sumShortTermTotal(index);
                            }}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            desc={
                              'Utang yang berkaitan selain dengan kegiatan usaha dan pajak'
                            }
                            controllerName={`financial_report.${index}.short_term_debt.other_debt`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Utang Lainnya'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.short_term_debt.other_debt`,
                                convertMoney(e.target.value)
                              );
                              sumShortTermTotal(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2"></div>
                      </div>
                      <div className="border-b" />

                      <span className="font-semibold text-[#01A24A]">
                        E. UTANG JANGKA PANJANG (lebih dari 1 tahun)
                      </span>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.long_term_debt.total`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Jumlah (D)'}
                            info={
                              'Jumlah dihitung berdasarkan data yang dimasukkan.'
                            }
                            placeholder={'0'}
                            required={true}
                            disabled
                          />
                        </div>
                        <div className="flex-1"></div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.long_term_debt.debt`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={'Utang Jangka Panjang'}
                            placeholder={'0'}
                            required={true}
                            disabled={isReadonly}
                            onChangeInput={(e) => {
                              setValue(
                                `financial_report.${index}.long_term_debt.debt`,
                                convertMoney(e.target.value)
                              );
                              sumLongTermTotal(index);
                            }}
                          />
                        </div>
                        <div className="flex-1"></div>
                      </div>
                      <div className="border-b" />
                    </>
                  </CardForm>
                  <CardForm
                    label={'Kekayaan Bersih'}
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    <>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.total_net_worth`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md ${
                              errors.nama_lemtara
                                ? 'border-[#F04438]'
                                : 'border-green'
                            }`}
                            label={
                              'Jumlah (aktiva/Aset) - (Pasiva/ kewajiban/ utang)'
                            }
                            placeholder={'0'}
                            required={true}
                            disabled
                          />
                        </div>
                        <div className="flex-1 pt-2"></div>
                      </div>
                    </>
                  </CardForm>
                  <CardForm
                    label={'Pendapatan dan Beban'}
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    <>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={'Rp. '}
                            controllerName={`financial_report.${index}.total_net_income`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md ${
                              errors.nama_lemtara
                                ? 'border-[#F04438]'
                                : 'border-green'
                            }`}
                            label={'Pendapatan Bersih (pendapatan - Beban)'}
                            placeholder={'0'}
                            required={true}
                            disabled
                          />
                        </div>
                        <div className="flex-1 pt-2"></div>
                      </div>
                      {draftLemtara?.label?.includes('Non') ? (
                        <>
                          <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <InputForm
                                showPrefix={true}
                                prefix={'Rp. '}
                                controllerName={`financial_report.${index}.total_income`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                  errors.nama_lemtara
                                    ? 'border-[#F04438]'
                                    : 'border-green'
                                }`}
                                desc={`Pendapatan yang berasal dari sumbangan, donasi, dan sejenisnya`}
                                label={'Kontribusi'}
                                placeholder={'0'}
                                required={true}
                                disabled={isReadonly}
                                onChangeInput={(e) => {
                                  setValue(
                                    `financial_report.${index}.total_income`,
                                    convertMoney(e.target.value)
                                  );
                                  sumTotalNetIncome(index);
                                }}
                              />
                            </div>
                            <div className="flex-1 pt-2">
                              <InputForm
                                showPrefix={true}
                                prefix={'Rp. '}
                                controllerName={`financial_report.${index}.total_incurred`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                  errors.nama_lemtara
                                    ? 'border-[#F04438]'
                                    : 'border-green'
                                }`}
                                desc={`Pendapatan yang berasal atas jasa yang diberikan dan investasi yang dihasilkan`}
                                label={'Pendapatan Lainnya'}
                                placeholder={'0'}
                                required={true}
                                disabled={isReadonly}
                                onChangeInput={(e) => {
                                  setValue(
                                    `financial_report.${index}.total_incurred`,
                                    convertMoney(e.target.value)
                                  );
                                  sumTotalNetIncome(index);
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <InputForm
                                showPrefix={true}
                                prefix={'Rp. '}
                                controllerName={`financial_report.${index}.program_costs`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                  errors.nama_lemtara
                                    ? 'border-[#F04438]'
                                    : 'border-green'
                                }`}
                                desc={`Biaya atau beban yang dikeluarkan untuk melakukan kegiatan/program`}
                                label={'Biaya Program'}
                                placeholder={'0'}
                                disabled={isReadonly}
                                onChangeInput={(e) => {
                                  setValue(
                                    `financial_report.${index}.program_costs`,
                                    convertMoney(e.target.value)
                                  );
                                  sumTotalNetIncome(index);
                                }}
                              />
                            </div>
                            <div className="flex-1 pt-2">
                              <InputForm
                                showPrefix={true}
                                prefix={'Rp. '}
                                desc={`Biaya atau beban yang dikeluarkan untuk memperoleh pendapatan`}
                                controllerName={`financial_report.${index}.non_program_costs`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                  errors.nama_lemtara
                                    ? 'border-[#F04438]'
                                    : 'border-green'
                                }`}
                                label={'Biaya Non Program'}
                                placeholder={'0'}
                                disabled={isReadonly}
                                onChangeInput={(e) => {
                                  setValue(
                                    `financial_report.${index}.non_program_costs`,
                                    convertMoney(e.target.value)
                                  );
                                  sumTotalNetIncome(index);
                                }}
                              />
                            </div>
                          </div>{' '}
                          {/* <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <InputForm
                                showPrefix={true}
                                prefix={"Rp. "}
                                controllerName={`financial_report.${index}.total_program_costs`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                  errors.nama_lemtara
                                    ? "border-[#F04438]"
                                    : "border-green"
                                }`}
                                label={"Total Biaya Program"}
                                placeholder={"0"}
                                required={true}
                                onChangeInput={(e) => {
                                  setValue(
                                    `financial_report.${index}.total_program_costs`,
                                    convertMoney(e.target.value)
                                  );
                                  sumTotalNetIncome(index);
                                }}
                                disabled
                              />
                            </div>
                            <div className="flex-1 pt-2">
                              <InputForm
                                showPrefix={true}
                                prefix={"Rp. "}
                                controllerName={`financial_report.${index}.total_non_program_costs`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                  errors.nama_lemtara
                                    ? "border-[#F04438]"
                                    : "border-green"
                                }`}
                                label={"Total Biaya Non Program"}
                                placeholder={"0"}
                                required={true}
                                onChangeInput={(e) => {
                                  setValue(
                                    `financial_report.${index}.total_non_program_costs`,
                                    convertMoney(e.target.value)
                                  );
                                  sumTotalNetIncome(index);
                                }}
                                disabled
                              />
                            </div>
                          </div> */}
                        </>
                      ) : (
                        <div className="flex gap-4">
                          <div className="flex-1 pt-2">
                            <InputForm
                              showPrefix={true}
                              prefix={'Rp. '}
                              controllerName={`financial_report.${index}.total_income`}
                              desc={`Seluruh pendapatan atau penerimaan yang diperoleh`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                errors.nama_lemtara
                                  ? 'border-[#F04438]'
                                  : 'border-green'
                              }`}
                              label={'Pendapatan'}
                              placeholder={'0'}
                              required={true}
                              disabled={isReadonly}
                              onChangeInput={(e) => {
                                setValue(
                                  `financial_report.${index}.total_income`,
                                  convertMoney(e.target.value)
                                );
                                sumTotalNetIncome(index);
                              }}
                            />
                          </div>
                          <div className="flex-1 pt-2">
                            <InputForm
                              showPrefix={true}
                              prefix={'Rp. '}
                              controllerName={`financial_report.${index}.total_incurred`}
                              desc={`Seluruh beban atau biaya yang dikeluarkan untuk menghasilkan pendapatan`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                errors.nama_lemtara
                                  ? 'border-[#F04438]'
                                  : 'border-green'
                              }`}
                              label={'Beban'}
                              placeholder={'0'}
                              required={true}
                              disabled={isReadonly}
                              onChangeInput={(e) => {
                                setValue(
                                  `financial_report.${index}.total_incurred`,
                                  convertMoney(e.target.value)
                                );
                                sumTotalNetIncome(index);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  </CardForm>
                  <CardForm
                    label={'Lampirkan Dokumen Pendukung'}
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    <>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputFormUpload
                            controllerName={`financial_report.${index}.url_file_report_financial`}
                            label={'Dokumen Laporan Keuangan'}
                            uploadFile={onUploadFile}
                            methods={methods}
                            disabled={isReadonly}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputFormRadio
                            label={
                              'Di audit kantor akuntan publik (KAP) atau tidak?*'
                            }
                            required={true}
                            controllerName={`financial_report.${index}.is_kap`}
                            values={[
                              {
                                label: 'Ya',
                                value: true,
                              },
                              {
                                label: 'Tidak',
                                value: false,
                              },
                            ]}
                            disabled={isReadonly}
                          />
                        </div>
                      </div>
                      {watch(`financial_report.${index}.is_kap`) === true && (
                        <>
                          <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <AtomSelect
                                controllerName={`financial_report.${index}.kap`}
                                options={[
                                  {
                                    value: 'Kantor Akuntan Bandung',
                                    label: 'Kantor Akuntan Bandung',
                                  },
                                  {
                                    value: 'Kantor Akuntan Jakarta',
                                    label: 'Kantor Akuntan Jakarta',
                                  },
                                  {
                                    value: 'Kantor Akuntan Surabaya',
                                    label: 'Kantor Akuntan Surabaya',
                                  },
                                  {
                                    value: 'Kantor Akuntan Bogor',
                                    label: 'Kantor Akuntan Bogor',
                                  },
                                ]}
                                className="basic-single mt-2"
                                label="KAP"
                                required={true}
                                returnObject={false}
                                disable={isReadonly}
                              />
                            </div>
                            <div className="flex-1 pt-2">
                              <AtomSelect
                                controllerName={`financial_report.${index}.kap_value`}
                                options={[
                                  {
                                    value: 'Wajar Dengan Pengecualian',
                                    label: 'Wajar Dengan Pengecualian',
                                  },
                                  {
                                    value: 'Tidak Memberikan Pendapat',
                                    label: 'Tidak Memberikan Pendapat',
                                  },
                                  {
                                    value: 'Tidak Wajar',
                                    label: 'Tidak Wajar',
                                  },
                                  {
                                    value: 'Tidak Diaudit',
                                    label: 'Tidak Diaudit',
                                  },
                                ]}
                                className="basic-single mt-2"
                                label="Hasil Opini Auditor"
                                required={true}
                                returnObject={false}
                                disable={isReadonly}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <InputForm
                                controllerName={`financial_report.${index}.kap_description`}
                                className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                                label={'Deskripsi hasil audit / opini auditor'}
                                placeholder={
                                  'Deskripsi hasil audit / opini auditor'
                                }
                                textArea={true}
                                required={true}
                                disabled={isReadonly}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </CardForm>
                </>
              </CardForm>
            );
          })}
        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          hideDraft={isReadonly}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            methods.handleSubmit(onSubmitForm)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default InformasiKeuanganLembaga;
