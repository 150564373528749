import React, { useEffect, useMemo, useState } from 'react';
import { BottomFormAction } from 'components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';

import {
  getInstitutionTypes,
  getIssueFocuses,
} from 'services/danaProgram/masterDataService';
import Form from './Form';

const ProfileUmum = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData = null,
}) => {
  const [jenisLembaga, setJenisLembaga] = useState([]);
  const [fokusIsu, setFokusIsu] = useState([]);
  const [isMapping, setIsMapping] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get('readonly') === 'true';

  const masterData = useSelector((state) => state?.draftMasterData?.data || {});
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = useMemo(() => {
    return yup.object({
      lemtara_name: yup.string().required(`Nama lemtara wajib diisi`),
      institution_type: yup.object({
        value: yup.string().required('Jenis lembaga wajib diisi'),
      }),
      intervention: yup
        .array()
        .required('Fokus isu wajib diisi')
        .min(1, 'Fokus isu wajib diisi'),
      email: yup
        .string()
        .email('Email tidak valid')
        .required(`Email wajib diisi`),
      phone: yup
        .string()
        .required('Nomor telepon wajib diisi')
        .matches(phoneRegExp, 'Nomor telepon tidak valid')
        .min(10, 'Nomor telepon minimal 10 digit')
        .max(13, 'Nomor telepon maksimum hanya 13 digit')
        .label('Nomor telepon'),
      contact_person: yup.string().required(`Nama narahubung wajib diisi`),
      directors: yup.string().required(`Nama direktur wajib diisi`),
      email_pic: yup
        .string()
        .email('Email tidak valid')
        .required(`Email wajib diisi`),
      gender_pic: yup.mixed().required('Jenis kelamin wajib diisi'),
      phone_pic: yup.string().matches(phoneRegExp, 'Nomor telepon tidak valid'),
      general_social_media: yup.object({
        website: yup
          .string()
          .matches(
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?$/,
            'Format website tidak valid'
          )
          .required(`Wesbite wajib diisi`),
        instagram: yup.string(),
        facebook: yup.string(),
        twitter: yup.string(),
        youtube: yup.string(),
      }),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    setValue,
    formState: { errors, isValid },
  } = methods;

  function containsObjectOrString(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === 'object') {
        return true;
      }
    }
    return false;
  }

  let genderOpt = [
    { value: 'L', label: 'Laki-laki' },
    { value: 'P', label: 'Perempuan' },
  ];

  const checkGender = (gender, val) => {
    return gender?.value === val;
  };

  const getInstitution = useMutation(async () => {
    try {
      const institutionResponse = await getInstitutionTypes();
      setJenisLembaga(
        institutionResponse?.data?.data?.map((data) => ({
          label: data?.nama,
          value: data?.id,
        }))
      );
    } catch (error) {
      throw error;
    }
  });

  const getIssue = useMutation(async () => {
    try {
      const issueResponse = await getIssueFocuses();
      setFokusIsu(
        issueResponse?.data?.data?.map((data) => ({
          label: data?.nama,
          value: data?.id,
        }))
      );
    } catch (error) {
      throw error;
    }
  });
  useEffect(() => {
    const mapDraftData = async () => {
      setIsMapping(true);
      await getInstitution.mutateAsync();
      await getIssue.mutateAsync();

      if (draftData && Object.keys(draftData).length > 0) {
        await Promise.all(
          Object.keys(draftData).map(async (key) => {
            if (
              key === 'institution_type' &&
              typeof draftData[key] !== 'object' &&
              draftData[key].constructor !== Object
            ) {
              let temp =
                draftData[key] === '1e14b408-c96a-4262-9529-3618119bbaa7'
                  ? {
                      id: '1e14b408-c96a-4262-9529-3618119bbaa7',
                      value: '1e14b408-c96a-4262-9529-3618119bbaa7',
                      label: 'Mencari Keuntungan',
                      name: 'Mencari Keuntungan',
                      deskripsi: 'Mencari Keuntungan',
                    }
                  : {
                      id: 'd8d306dc-e0ec-41f0-aabf-7e1ed8d12d12',
                      nama: 'Tidak Mencari Keuntungan (Non-Profit)',
                      value: 'd8d306dc-e0ec-41f0-aabf-7e1ed8d12d12',
                      label: 'Tidak Mencari Keuntungan (Non-Profit)',
                      deskripsi: 'Tidak Mencari Keuntungan (Non-Profit)',
                    };
              setValue(key, temp);
            } else if (key.includes('gender')) {
              if (typeof draftData[key] === 'string') {
                let temp =
                  draftData[key] === 'L'
                    ? { value: 'L', label: 'Laki-laki' }
                    : { value: 'P', label: 'Perempuan' };
                setValue(key, temp);
              } else {
                setValue(key, draftData[key]);
              }
            } else if (
              key === 'intervention' &&
              Array.isArray(draftData[key]) &&
              !containsObjectOrString(draftData[key])
            ) {
              setValue(
                'intervention',
                fokusIsu.filter((item) => draftData[key].includes(item.value))
              );
            } else {
              setValue(key, draftData[key]);
            }
          })
        );
      }
      setIsMapping(false);
      methods.trigger();
    };

    mapDraftData();
  }, [draftData, setValue, methods]);

  return (
    <>
      <FormProvider {...methods}>
        <Form
          draftData={draftData}
          methods={methods}
          jenisLembagaLoading={getInstitution.isLoading}
          jenisLembaga={jenisLembaga}
          fokusIsuLoading={getIssue.isLoading}
          fokusIsu={fokusIsu}
          isMapping={isMapping}
        />

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={isReadonly ? true : false}
          drafButtonAction={() => {
            const values = methods.getValues();

            onDraftSubmit(values);
          }}
          hideDraft={isReadonly}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default ProfileUmum;
