import { Button, ConfirmationModal, Spinner } from 'components';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { RiAddLine } from 'react-icons/ri';
import TablePagination from 'components/atoms/Pagination';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { postSubmitReport } from 'services/danaProgram/callForProposalService';
import { enqueueSnackbar } from 'notistack';
import { AiOutlineEye } from 'react-icons/ai';
import { post, postApprove } from 'services/danaProgram/disbursement';
import Select from '../Components/Select';
import SearchInput from '../Components/SearchInput';
import CustomDatepicker from '../Components/Datepicker';
import { useDisbursement } from 'pages/DanaProgram/Disbursement/hooks';
import useReportActivity from './Hooks/useReportActivity';
import { formatCurrency } from 'components/molecules/InputForm';

const rowPerPageOptions = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
];

const STATUS_ARRAY = [
  'Menunggu Verifikasi',
  'Telah Diverifikasi',
  'Perlu Revisi',
  'Ditolak',
];

const StatusBadge = ({ status }) => {
  let defaultDotClass = 'bg-gray-500';
  let defaultWrapperClass = 'bg-gray-50 text-gray-700';

  switch (status) {
    case 'Telah Diverifikasi':
      defaultDotClass = 'bg-bluelight-500';
      defaultWrapperClass = 'bg-bluelight-50 text-bluelight-700';
      break;
    case 'Menunggu Verifikasi':
      defaultDotClass = 'bg-purple-500';
      defaultWrapperClass = 'bg-purple-50 text-purple-700';
      break;
    case 'Perlu Revisi':
      defaultDotClass = 'bg-pink-500';
      defaultWrapperClass = 'bg-pink-50 text-pink-700';
      break;
    case 'Ditolak':
      defaultDotClass = 'bg-red-500';
      defaultWrapperClass = 'bg-red-50 text-red-700';
      break;

    default:
      defaultDotClass = 'bg-gray-500';
      defaultWrapperClass = 'bg-gray-50 text-gray-700';
      break;
  }
  return (
    <div
      className={`rounded-2xl flex items-center border-transparent p-2 text-xs w-fit ${defaultWrapperClass}`}
    >
      <div
        className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`}
      />
      <div className="flex-1 text-center whitespace-nowrap">{status}</div>
    </div>
  );
};

const ReportActivity = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [dataSource, setDataSource] = useState({});
  const [statusFilter, setStatusFilter] = useState(null);
  const [submitedDate, setSubmitedDate] = useState(null);
  const [rowPerPage, setRowPerPage] = useState({ value: 10, label: '10' });
  const [modal, setModal] = useState({
    title: '',
    description: '',
    type: '',
    open: false,
    data: null,
    cancelLabel: '',
    confirmLabel: '',
  });
  const params = {
    page: page,
    limit: limit,
    name: keyword,
    status: statusFilter?.value,
    submited_date: submitedDate && moment(submitedDate).format('YYYY-MM-DD'),
  };

  const {
    reportActivityData,
    isLoadingReportActivityData,
    isFetchingReportActivityData,
    refetchReportActivityData,
  } = useReportActivity(params);

  useEffect(() => {
    refetchReportActivityData();
  }, [page, limit, keyword, statusFilter, submitedDate]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const changePage = (val) => {
    setPage(val);
    wrapperRef.current.scrollIntoView({ behaviour: 'smooth' });
  };

  const changeRowsPerPage = (page) => {
    setRowPerPage(page);
    setPage(1);
    setLimit(page.value);
    wrapperRef.current.scrollIntoView({ behaviour: 'smooth' });
  };

  // const onSubmitReport = useMutation({
  //   mutationKey: ["submit-report-call-for-proposal"],
  //   mutationFn: async () => {
  //     const res = await postSubmitReport({ id: modal?.data })
  //     return res;
  //   },
  //   onSuccess: () => {
  //     setModal(curr => ({
  //       ...curr,
  //       type: '',
  //       title: '',
  //       open: false,
  //       data: null,
  //       cancelLabel: '',
  //       confirmLabel: ''
  //     }));
  //     enqueueSnackbar("Laporan berhasil disubmit", { variant: "success" });
  //     refetchReportActivityData();
  //   }
  // });

  // const batalkanPermohonan = useMutation({
  //   mutationKey: ["post-batalkan-permohonan-pembayaran"],
  //   mutationFn: async (payload) => {
  //     const res = await postApprove(payload);
  //     return res;
  //   },
  //   onSuccess: (res) => {
  //     enqueueSnackbar("Data Berhasil Disimpan", { variant: "success" });
  //     navigate(`/disbursement/application`);
  //   },
  // });

  return (
    <Fragment>
      {/* <ConfirmationModal
        useGraphic
        open={modal.open && modal.type === "confirm"}
        onCancel={() => {
          setModal(curr => ({
            ...curr,
            type: '',
            title: '',
            description: '',
            open: false,
            data: null
          }))
        }}
        cancelLabel={modal.cancelLabel}
        confirmLabel={modal.confirmLabel}
        onSubmit={() => onSubmitReport.mutate()}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {modal.title}
          </div>
          <div className="text-sm text-gray-600">
            {modal.description}
          </div>
        </div>
      </ConfirmationModal> */}
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: 'Laporan Kegiatan',
                  path: `/report/activity`,
                },
              ]}
            />
            <div className="mt-6 flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span
                    className="font-semibold text-[24px] text-gray-900"
                    ref={wrapperRef}
                  >
                    Laporan Kegiatan dan Keuangan
                  </span>
                </div>
              </div>
              <div className="flex-1 text-right">
                <Button
                  type="button"
                  className="rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white px-4 py-2.5"
                  label={
                    <div className="flex items-center gap-2">
                      <RiAddLine />
                      <span className="font-semibold text-sm">
                        Tambah Laporan
                      </span>
                    </div>
                  }
                  onClick={() => navigate('/report/activity/form')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4 p-3 rounded-xl bg-white border border-gray-200">
          <div className="flex flex-col gap-5">
            <div className="flex gap-5">
              <div className="w-full">
                <SearchInput onChange={onSearch} />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="w-1/2">
                <CustomDatepicker
                  placeholder="Tanggal Laporan"
                  onChange={(dates) => {
                    setSubmitedDate(dates);
                  }}
                  onClear={() => {
                    setSubmitedDate(null);
                  }}
                  value={submitedDate}
                />
              </div>
              <div className="w-1/2">
                <Select
                  placeholder="Status"
                  value={statusFilter}
                  optionsData={STATUS_ARRAY.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  onChange={setStatusFilter}
                  isClearable={true}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
          </div>
          {isLoadingReportActivityData ? (
            <div className="min-h-[500px] flex items-center justify-center">
              <Spinner theme="primary" />
            </div>
          ) : reportActivityData?.data?.length > 0 ? (
            <div className="space-y-4">
              {reportActivityData?.data?.map((x, key) => {
                const month = [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'Mei',
                  'Jun',
                  'Jul',
                  'Agu',
                  'Sep',
                  'Okt',
                  'Nov',
                  'Des',
                ];
                console.log('ini adalah submitted date', x);
                const splitSubmitedDate = x?.submited_date?.split('/');
                let activityDate = x?.activity_date;
                if (Array.isArray(x?.activity_date)) {
                  activityDate = x?.activity_date?.map((p, x) => {
                    const reformat = `${new Date(p).getDate()} ${month[new Date(p).getMonth()]
                      } ${new Date(p).getFullYear()}`;
                    return reformat;
                  });
                  activityDate = activityDate?.join(' - ');
                }

                return (
                  <div
                    key={key}
                    className="p-5 rounded-xl border border-gray-200 space-y-3"
                  >
                    <div className="flex w-full">
                      <div className="flex flex-col w-full gap-3">
                        <div className="flex w-full justify-between gap-3">
                          <div className="w-1/3">
                            <span className="font-bold text-sm">
                              Tanggal Laporan:{' '}
                              {/* COMMENT: Use created_at temporarily */}
                              {/* {`${splitSubmitedDate[1]}/${splitSubmitedDate[0]}/${splitSubmitedDate[2]}`} */}
                              {moment(x.created_at).format('DD/MM/YYYY')}
                            </span>
                          </div>
                          <div className="w-1/3"></div>
                          <div className="w-1/3">
                            <span>
                              <StatusBadge status={'Menunggu Verifikasi'} />
                            </span>
                          </div>
                        </div>
                        <div className="flex w-full justify-between gap-3">
                          <div className="w-1/3">
                            <div>Judul Proposal : </div>
                            <div className="font-bold">{x.proposal_name}</div>
                          </div>
                          <div className="w-1/3">
                            <div>Output : </div>
                            <div className="font-bold truncate">{x.output}</div>
                          </div>
                          <div className="w-1/3">
                            <div>Tanggal Kegiatan : </div>
                            <div className="font-bold">{activityDate}</div>
                          </div>
                        </div>
                        <div className="flex w-full justify-between gap-3">
                          <div className="w-1/3">
                            <div>Proyek : </div>
                            <div className="font-bold">FCFF</div>
                          </div>
                          <div className="w-1/3">
                            <div>Kegiatan Utama : </div>
                            <div className="font-bold">{x.activity_name}</div>
                          </div>
                          <div className="w-1/3">
                            <div>Realisasi Biaya : </div>
                            <div className="font-bold">
                              Rp.{
                                x?.total_realized_value ? formatCurrency(String(x?.total_realized_value)) : '-'
                              },-
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-[20%] gap-4 justify-center">
                        <span>
                          <Button
                            type="button"
                            size="sm"
                            className="py-2 px-3.5 bg-red-50 rounded-lg w-full h-full"
                            onClick={() => {
                              // batalkanPermohonan.mutate({
                              //   "id": x?._id,
                              //   "status": "Dibatalkan"
                              // });
                              console.log('ubah');
                            }}
                            label={
                              <div className="flex items-center gap-2 text-red-700 text-xs font-semibold">
                                <span>Ubah</span>
                              </div>
                            }
                          />
                        </span>
                        <span>
                          <Button
                            type="button"
                            size="sm"
                            className="py-2 px-3.5 border border-gray-300 bg-white rounded-lg w-full h-full"
                            onClick={() =>
                              // navigate(`/disbursement/application/${x?._id}`)
                              navigate('/report/activity/form?detail=true&id=' + x?._id)
                            }
                            label={
                              <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                                <AiOutlineEye size={20} />
                                <span>Lihat Detail</span>
                              </div>
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              <hr />
              <div className="flex items-center justify-end px-4 pb-4 pt-3">
                <div className="flex space-x-3 items-center">
                  <p className="mb-0 text-gray-600 text-sm">Show Data</p>
                  <Select
                    value={rowPerPage}
                    optionsData={rowPerPageOptions}
                    onChange={changeRowsPerPage}
                    menuPortalTarget={document.body}
                  />
                  <p className="mb-0 text-gray-600 text-sm">{`${(page - 1) * rowPerPage.value || 1
                    } - ${page <= 1 && reportActivityData?.total <= rowPerPage.value
                      ? reportActivityData?.total
                      : page * rowPerPage.value
                    } data from ${reportActivityData?.total} data.`}</p>
                </div>
                <div className="flex flex-1 justify-end">
                  <TablePagination
                    page={page}
                    totalPage={reportActivityData?.lastPage}
                    onChange={changePage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center w-full mt-5">
              <p>No Data</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ReportActivity;
